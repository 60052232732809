import React, { useState } from "react";
import { connect } from "react-redux";
import { resetLogInConfirm } from "../actions/auth";

const ResetPwdConfirm = ({ resetLogInConfirm, match }) => {
  const [show1, setShow1] = useState(null);
  const [ErrorMessage, setErrorMessage] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();

    const uid = match.params.uid;
    const token = match.params.token;

    let res = await resetLogInConfirm(uid, token);

    setErrorMessage(res);

    setShow1(true);

    setTimeout(() => {
      window.location = `${process.env.REACT_APP_API_URL}/login`
    }, 1500);
  };

  const DontReset = () => {
    setShow1(false);

    setErrorMessage(
      "Clicked on No button, You'll be now re-directed to logIn Page."
    );
    setTimeout(() => {
      // window.location = 'https://nautilus.fimmtech.com/login'
      window.location = "http://localhost:3000/login";
    }, 2000);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="card mt-4" style={{ width: "90%" }}>
                      {show1 === true ? (
                        <div
                          className={ErrorMessage.includes("expired") ? "alert alert-danger alert-dismissible fade show" : "alert alert-success alert-dismissible fade show"}
                          role="alert"
                        >
                          {ErrorMessage}
                        </div>
                      ) : show1 === false ? (
                        <div
                          className="alert alert-danger alert-dismissible fade show"
                          role="alert"
                        >
                          {ErrorMessage}
                        </div>
                      ) : null}

                      <div className="card-body">
                        <div className="text-center">
                          <h5 className="card_head">
                            {" "}
                            Confirm LogIn Reset Status.{" "}
                          </h5>
                        </div>

                        <hr></hr>

                        <div className="d-flex flex-column justify-content-between w-100">

                          <div className="pb-3">
                            <span style={{ color: "red" }}>
                              <strong>
                                {" "}
                                Are you sure you want to reset your login
                                status?{" "}
                              </strong>
                            </span>
                          </div>

                          <div className="d-flex">
                            <div>
                              <button
                                style={{ width: "10vw" }}
                                className="btn-primary"
                                onClick={(e) => onSubmit(e)}
                              >
                                {" "}
                                Yes{" "}
                              </button>
                            </div>
                            <div>
                              <button
                                style={{ width: "10vw" }}
                                className="ml-2 btn-secondary"
                                onClick={DontReset}
                              >
                                {" "}
                                No{" "}
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { resetLogInConfirm })(ResetPwdConfirm);
