import React from "react";
import { GridComponent } from "@syncfusion/ej2-react-grids";

const Time = ({
  TimeHrsMetricDecimals,
  TimeMinMetricDecimals,
  TimeSecMetricDecimals,
  StoredUnits,
  DistanceUnits,
  SpeedUnits,
  PressureUnits,
  TemperatureUnits,
  TimeUnits,
  AreaUnits,
  WeightUnits,
  setSelectedUnitsData,
  showAlert,
}) => {
  // Function to store the metric units data
  const StoreTimeMetricUnits = (data) => {
    showAlert.current = true;

    TimeUnits.current = {
      unit_id:
        data.index === "0"
          ? StoredUnits.Unit_Data[0].Time[0].unit_id
          : data.index === "1"
          ? StoredUnits.Unit_Data[0].Time[1].unit_id
          : data.index === "2"
          ? StoredUnits.Unit_Data[0].Time[2].unit_id
          : null,
      unit_name:
        data.index === "0"
          ? StoredUnits.Unit_Data[0].Time[0].unit_name
          : data.index === "1"
          ? StoredUnits.Unit_Data[0].Time[1].unit_name
          : data.index === "2"
          ? StoredUnits.Unit_Data[0].Time[2].unit_name
          : null,
      decimals:
        data.MetricUnit === StoredUnits.Unit_Data[0].Time[0].unit_name
          ? TimeSecMetricDecimals.current
          : data.MetricUnit === StoredUnits.Unit_Data[0].Time[1].unit_name
          ? TimeMinMetricDecimals.current
          : data.MetricUnit === StoredUnits.Unit_Data[0].Time[2].unit_name
          ? TimeHrsMetricDecimals.current
          : null,
    };

    // console.log(TimeUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  function TimeMetricRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="TimeUnit"
        defaultChecked={
          (props.index === "0" &&
            TimeUnits.current.unit_name ===
              StoredUnits.Unit_Data[0].Time[0].unit_name) ||
          (props.index === "1" &&
            TimeUnits.current.unit_name ===
              StoredUnits.Unit_Data[0].Time[1].unit_name) ||
          (props.index === "2" &&
            TimeUnits.current.unit_name ===
              StoredUnits.Unit_Data[0].Time[2].unit_name)
            ? true
            : false
        }
        onClick={() => StoreTimeMetricUnits(props)}
      />
    );
  }

  function TimeMetricSecDropDownTemplate(props) {
    // console.log(props)

    return (
      <select
        className="w-100"
        defaultValue={
          props.MetricUnit === StoredUnits.Unit_Data[0].Time[0].unit_name
            ? TimeSecMetricDecimals.current
            : props.MetricUnit === StoredUnits.Unit_Data[0].Time[1].unit_name
            ? TimeMinMetricDecimals.current
            : props.MetricUnit === StoredUnits.Unit_Data[0].Time[2].unit_name
            ? TimeHrsMetricDecimals.current
            : null
        }
        onChange={(e) => {
          const value = e.target.value;
          switch (props.MetricUnit) {
            case StoredUnits.Unit_Data[0].Time[0].unit_name:
              TimeSecMetricDecimals.current = value;
              StoreTimeMetricUnits(props);
              break;
            case StoredUnits.Unit_Data[0].Time[1].unit_name:
              TimeMinMetricDecimals.current = value;
              StoreTimeMetricUnits(props);
              break;
            case StoredUnits.Unit_Data[0].Time[2].unit_name:
              TimeHrsMetricDecimals.current = value;
              StoreTimeMetricUnits(props);
              break;
            default:
              // Handle other cases if necessary
              break;
          }
        }}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  let TimeData = [
    {
      MetricUnit: `${StoredUnits.Unit_Data[0].Time[0].unit_name}` || null,
      Metric: 20,
      MetricDecimal: 0,
    },
    {
      MetricUnit: `${StoredUnits.Unit_Data[0].Time[1].unit_name}` || null,
      Metric: 20,
      MetricDecimal: 0.1,
    },
    {
      MetricUnit: `${StoredUnits.Unit_Data[0].Time[2].unit_name}` || null,
      Metric: 20,
      MetricDecimal: 0.12,
    },
  ];

  const TimeColumn = [
    {
      field: "MetricUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "Metric",
      template: TimeMetricRadioTemplate.bind(this),
      headerText: "English \\ Metric",
      width: 100,
    },
    {
      field: "MetricDecimal",
      headerText: "Decimals",
      template: TimeMetricSecDropDownTemplate.bind(this),
      width: 100,
    },
  ];

  return (
    <div>
      <GridComponent
        id="Grid"
        dataSource={TimeData}
        columns={TimeColumn}
      ></GridComponent>
    </div>
  );
};

export default Time;
