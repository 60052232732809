import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const RegrindCalculator = ({ calculateResult, RegrindResult, RegrindData }) => {

  const handleChange = (event) => {

    RegrindData = { ...RegrindData, [event.target.name]: event.target.value }

    let flag = true;

    for (let key in RegrindData) {
      if (RegrindData[key] <= 0)
        flag = false;
    }

    if (flag) {
      calculateResult(RegrindData);
    }

  };

  return (
    <div>
      <Form>
        <Container>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Row>
                  <Col sm={4}>
                    <Form.Label>Enter Single Part Weight:</Form.Label> </Col>
                  <Col sm={4}>
                    <Form.Control
                      name="singlePartWeight"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0.0-9.0]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      defaultValue={RegrindData.singlePartWeight}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Row>
                  <Col sm={4}>
                    <Form.Label>Number of Cavities:</Form.Label> </Col>
                  <Col sm={4}>
                    <Form.Control
                      name="cavities"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      defaultValue={RegrindData.cavities}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Row>
                  <Col sm={4}>
                    <Form.Label>Total weight of all cavities:</Form.Label></Col>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0.0-9.0]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      defaultValue={Number(RegrindData.singlePartWeight * RegrindData.cavities).toFixed(0)}
                      readOnly
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Row>
                  <Col sm={4}>
                    <Form.Label>Enter Single Runner Weight:</Form.Label></Col>
                  <Col sm={4}>
                    <Form.Control
                      name="singleRunnerWeight"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0.0-9.0]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      defaultValue={RegrindData.singleRunnerWeight}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Row>
                  <Col sm={4}>
                    <Form.Label>Number of Runner:</Form.Label></Col>
                  <Col sm={4}>
                    <Form.Control
                      name="runner"
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0.0-9.0]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      defaultValue={RegrindData.runner}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Row>
                  <Col sm={4}>
                    <Form.Label>Total weight of all Runners:</Form.Label> </Col>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      onKeyPress={(event) => {
                        if (!/[0.0-9.0]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      defaultValue={Number(RegrindData.singleRunnerWeight * RegrindData.runner).toFixed(0)}
                      readOnly
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>

          </Row>
        </Container>

        <Container>

          <Row>

            <Col>
              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Row>
                  <Col sm={5}>
                    <Form.Label>Total Shot Weight:</Form.Label> </Col>
                  <Col sm={4}>
                    <Form.Control
                      name="totalShotWeight"
                      type="number"
                      defaultValue={Number(RegrindResult.totalShotWeight).toFixed(0)}
                      readOnly
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>

            <Col>

              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Row>
                  <Col sm={5}>
                    <Form.Label>Part Weight (%):</Form.Label> </Col>
                  <Col sm={4}>
                    <Form.Control
                      type="number"
                      defaultValue={Number(RegrindResult.partWeight).toFixed(2)}
                      readOnly
                    />
                  </Col>
                </Row>
              </Form.Group>

            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Row>
                  <Col sm={5}>
                    <Form.Label>Runner Weight (%):</Form.Label></Col>
                  <Col sm={4}>
                    <Form.Control
                      type="number"
                      defaultValue={Number(RegrindResult.totalRunnerWeight).toFixed(2)}
                      readOnly
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>

          </Row>

          {/* <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Form.Label>Enter Pass# (p):</Form.Label>
                <Col sm={10}>
                  <Form.Control
                    name="pass"
                    type="number"
                    defaultValue={RegrindData.pass}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="formBasicnumber">
                <Form.Label>Enter Generation# (g):</Form.Label>
                <Col sm={10}>
                  <Form.Control
                    name="gen"
                    type="number"
                    defaultValue={RegrindData.gen}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row> */}
        </Container>
      </Form>

      {/* <p>
        In the part,Amount of {RegrindData.gen} generation regrind in {RegrindData.pass} number pass is {RegrindResult.regrindAmount}
      </p> */}
    </div>
  );
};

export default RegrindCalculator;
