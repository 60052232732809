import React from "react";
import "../../assets/custom-stylesheet/app2_style.css";
import "../../assets/custom-stylesheet/samplepage_style.css";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import MoldPdf from "./MoldQualification.pdf";

const MoldQualificatios = () => {
  return (
    <>
      <div id="container">

        <iframe
          src={MoldPdf}
          width="100%"
          height="600px"
          title="PDF Viewer"
        />
        
      </div>
    </>
  );
};
export default MoldQualificatios;
