import React from "react";
import "../../assets/custom-stylesheet/app2_style.css";
import "../../assets/custom-stylesheet/samplepage_style.css";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import {
    TabComponent,
    TabItemDirective,
    TabItemsDirective,
  } from "@syncfusion/ej2-react-navigations";
// import Conversions from "../conversions/Conversions";
import DefectSolution from "./Defect/DefectSolution";
import ProcessParameter from "./ProcessParameter/ProcessParameter";
import { useHistory } from 'react-router-dom';

const MoldTroubleshoot = () => {

    const history = useHistory();

    let headertext = [
        { text: "Defect-solution" },
        { text: "Process Parameter Effect" },
            ];

            function content0() {
                return (
                  <div className="card p-3 ml-2 b-dark">
                    <DefectSolution/>
                  
                  </div>
                );
              }
            
              function content1() {
                return (
                  <div className="card p-3 ml-2 b-dark">
                    <ProcessParameter />
                  </div>
                );
              }       

    return (
        <>
            <div className="container-fluid">
                <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
                    <div className="d-flex">
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '14px', color:'blue' }} onClick={() => history.push('/mold')} > Molds </span>
                    </div>
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '16px' }}> {">"} </span>
                    </div>
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '14px' }}>Mold Trouble Shooting </span>
                    </div>
                    </div>
                </div>

                <div>

                <div className="d-flex">
                <div className="col-md-12">

                <TabComponent heightAdjustMode="Auto" id="defaultTab" style={{padding:"0 16",}}>
                <TabItemsDirective>
                  <TabItemDirective header={headertext[0]} content={content0} />
                  <TabItemDirective header={headertext[1]} content={content1} />

                  </TabItemsDirective>
                  
              </TabComponent>

              </div>
              </div>


            </div>

            </div>
            
        </>
    );
};

export default MoldTroubleshoot;

