import React from "react";
import {
  HtmlEditor,
  Toolbar,
  RichTextEditorComponent,
  Inject,
} from "@syncfusion/ej2-react-richtexteditor";

const Notes = ({ PPS_Data }) => {

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  }

  const CreateInputData = (event) => {

    if (event.target) {
      PPS_Data.Notes = event.target.value
    } else {
      PPS_Data.Notes = event.value
    }

  }

  return (
    <div>
      <div className="TabCardTitle">
        <span> Notes </span>
      </div>
      <div>
        <RichTextEditorComponent
          value={""}
          change={CreateInputData}
          toolbarSettings={toolbarSettings}
          height={"60vh"}
          width={"90vw"}
        >
          <Inject services={[Toolbar, HtmlEditor]} />
        </RichTextEditorComponent>
      </div>
    </div>
  );
};

export default Notes;
