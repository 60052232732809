import React, { useState, useRef } from "react";
// Tab view component from syncfusion to navigate through six steps study
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import Header2 from "../common/header-component/header2";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import InjSpeed from "./Inj_Speed_Linearity/InjSpeed";
import ShotRepeatability from "./Shot_Repeatability_Study/ShotRepeatability";
import LoadSensitivity from "./Load_Sensitivity_Test/LoadSensitivity";

const EquipmentDashboard = () => {
  const history = useHistory();

  const [MoldId, setMoldId] = useState();
  const [MoldName, setMoldName] = useState("mold");
  const [Session_Name, setSession_Name] = useState("");

  // Declare the names of the tab's
  let headertext = [
    { text: "Inj Speed Linearity" },
    { text: "Shot Repeatability Study" },
    { text: "Load Sensitivity Test" },
  ];

  // eslint-disable-next-line
  const showAlert = useRef(false);

  // These are the event's which will get called when clicked on the respective step's tab and once they are called they render the component of that respective tab which we have imported above
  function content0() {
    return (
      <div>
        <InjSpeed
          setSession_Name={setSession_Name}
          setMoldId={setMoldId}
          setMoldName={setMoldName}
        />
      </div>
    );
  }

  function content1() {
    return (
      <div>
        <ShotRepeatability />
      </div>
    );
  }

  function content2() {
    return (
      <div>
        <LoadSensitivity />
      </div>
    );
  }

  // Event to get back to Session's
  const goBack = () => {
    history.push(
      `/dashboard/${MoldId}/${MoldName}/equipmentqualification/sessions`
    );
  };

  return (
    <>
      <Header2 Title="Equipment Qualification" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">
            <div>
              <span
                className="BreadCrum"
                onClick={goBack}
                style={{ fontSize: "14px", color:"blue"}}
              >
                {" "}
                Sessions{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "14px" }}>
                {" "}
                Equipment Qualification{" "}
              </span>
            </div>
          </div>

          {/* <div className="d-flex">
            <div>
              <span className="Mold-Session">
                {" "}
               Mold Id : {MoldName ? atob(MoldName) : ""}{" "}
              </span>
            </div>

            <div className="ml-4">
              <span className="Mold-Session">
                {" "}
                Session Name : {Session_Name}{" "}
              </span>
            </div>
          </div> */}
        </div>

        {/* <div className="study-container"> */}

        <div className="d-flex">
          <div className="col-md-10">
               
          
              {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}
              <TabComponent heightAdjustMode="Auto" id="defaultTab">
                <TabItemsDirective>
                  <TabItemDirective header={headertext[0]} content={content0} />
                  <TabItemDirective header={headertext[1]} content={content1} />
                  <TabItemDirective header={headertext[2]} content={content2} />
                </TabItemsDirective>
              </TabComponent>
            

          </div>
          <div className="col-md-2"> 
              {/* <button className="btn btn-secondary btn-air-secondary" type="button"> Save </button> */}
              {/* <button className="btn btn-secondary btn-air-secondary  mr-2 ml-2" type="button"> Print </button> */}
            </div>

            </div>


        {/* </div> */}

        <ToastContainer />
      </div>
    </>
  );
};

export default EquipmentDashboard;
