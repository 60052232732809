import React from "react";
import { GridComponent } from "@syncfusion/ej2-react-grids";

const Distance = ({
  DistanceEnglishDecimals,
  DistanceMetricDecimals,
  setDistanceEnglishDecimals,
  setDistanceMetricDecimals,
  StoredUnits,
  DistanceUnits,
  SpeedUnits,
  PressureUnits,
  TemperatureUnits,
  TimeUnits,
  AreaUnits,
  WeightUnits,
  setSelectedUnitsData,
  showAlert,
}) => {
  // Function to store the metric units data
  const StoreDistanceMetricUnits = () => {
    showAlert.current = true;

    setDistanceEnglishDecimals(0.12);

    DistanceUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Distance[0].unit_id,
      unit_name: StoredUnits.Unit_Data[0].Distance[0].unit_name,
      decimals: DistanceMetricDecimals,
    };

    // console.log(DistanceUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // Function to store the english units data
  const StoreDistanceEnglishUnits = () => {
    showAlert.current = true;

    setDistanceMetricDecimals(0.12);

    DistanceUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Distance[1].unit_id,
      unit_name: StoredUnits.Unit_Data[0].Distance[1].unit_name,
      decimals: DistanceEnglishDecimals,
    };

    // console.log(DistanceUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  const StoreDistanceMetricDecimals = (e) => {
    setDistanceMetricDecimals(e.target.value);

    DistanceUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Distance[0].unit_id,
      unit_name: StoredUnits.Unit_Data[0].Distance[0].unit_name,
      decimals: parseFloat(e.target.value),
    };

    // console.log(DistanceUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // HTML input template to bind with the grid for metric part
  function DistanceMetricRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="DistanceUnit"
        defaultChecked={
          DistanceUnits.current.unit_id ===
          StoredUnits.Unit_Data[0].Distance[0].unit_id
            ? true
            : false
        }
        onClick={StoreDistanceMetricUnits}
      />
    );
  }

  const StoreDistanceEnglishDecimals = (e) => {
    setDistanceEnglishDecimals(e.target.value);

    DistanceUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Distance[1].unit_id,
      unit_name: StoredUnits.Unit_Data[0].Distance[1].unit_name,
      decimals: parseFloat(e.target.value),
    };

    // console.log(DistanceUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // HTML input template to bind with the grid for english part
  function DistanceEnglishRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="DistanceUnit"
        defaultChecked={
          DistanceUnits.current.unit_id ===
          StoredUnits.Unit_Data[0].Distance[1].unit_id
            ? true
            : false
        }
        onClick={StoreDistanceEnglishUnits}
      />
    );
  }

  // HTML drop down template to bind with the grid for metric part
  function DistanceDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        defaultValue={DistanceMetricDecimals}
        onChange={StoreDistanceMetricDecimals}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  // HTML drop down template to bind with the grid for english part
  function DistanceEnglishDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        defaultValue={DistanceEnglishDecimals}
        onChange={StoreDistanceEnglishDecimals}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  let DistanceData = [
    {
      MetricUnit:
        `${StoredUnits.Unit_Data[0].Distance[0].unit_name}` || null,
      Metric: 20,
      MetricDecimal: 0.12,
      EnglishUnit:
        `${StoredUnits.Unit_Data[0].Distance[1].unit_name}` || null,
      English: 20,
      EnglishDecimal: 0.12,
    },
  ];

  const DistanceColumn = [
    {
      field: "MetricUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "Metric",
      template: DistanceMetricRadioTemplate.bind(this),
      headerText: "Metric",
      width: 100,
    },
    {
      field: "MetricDecimal",
      headerText: "Decimals",
      template: DistanceDropDownTemplate.bind(this),
      width: 100,
    },
    {
      field: "EnglishUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "English",
      headerText: "English",
      width: 100,
      template: DistanceEnglishRadioTemplate.bind(this),
    },
    {
      field: "EnglishDecimal",
      headerText: "Decimals",
      template: DistanceEnglishDropDownTemplate.bind(this),
      width: 100,
    },
  ];

  return (
    <div>
      <GridComponent
        id="Grid"
        dataSource={DistanceData}
        columns={DistanceColumn}
      ></GridComponent>
    </div>
  );
};

export default Distance;
