import React from "react";
import "../../assets/custom-stylesheet/app2_style.css";
import "../../assets/custom-stylesheet/samplepage_style.css";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import'../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import {
    TabComponent,
    TabItemDirective,
    TabItemsDirective,
  } from "@syncfusion/ej2-react-navigations";
import ScientificMold from "./ScientificMold";
import SixSteps from "./SixSteps";
import MoldQualificatios from "./MoldQualification";
import { useHistory } from 'react-router-dom';
import GeneralTrouble from "./GeneralTroubleshooting/GenralTrouble";
const LearningCenter = () => {

    const history = useHistory();

    let headertext = [
        { text: "General TroubleShooting" },
        { text: "Scientific Molding Concepts" },
        { text: "6 Step Procedures" },
        { text: "Mold Qualification Flowchart" },
            ];

            function content0() {
                return (
                  <div className="card p-3 ml-2 b-dark">
                    <GeneralTrouble/>
                  
                  </div>
                );
              }
            
              function content1() {
                return (
                  <div className="card p-3 ml-2 b-dark">

                      <ScientificMold/>


                  </div>
                );
              }     
              
              function content2() {
                return (
                  <div className="card p-3 ml-2 b-dark">
                   <SixSteps />
                  </div>
                );
              }    
              
              function content3() {
                return (
                  <div className="card p-3 ml-2 b-dark">
                  <MoldQualificatios />

                  </div>
                );
              }       



    return (
        <>
            <div className="container-fluid">
                <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
                    {/* <div className="d-flex">
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '14px', color:'blue' }} onClick={() => history.push('/dashboard/mold')} > Molds </span>
                    </div>
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '16px' }}> {">"} </span>
                    </div> */}
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '14px' }}>Learning Center</span>
                    </div>
                    </div>
                </div>

                <div>

                <div className="d-flex">
                <div className="col-md-12">

                <TabComponent heightAdjustMode="Auto" id="defaultTab" style={{padding:"0 16",}}>
                <TabItemsDirective>
                  <TabItemDirective header={headertext[0]} content={content0} />
                  <TabItemDirective header={headertext[1]} content={content1} />
                  <TabItemDirective header={headertext[2]} content={content2} />
                  <TabItemDirective header={headertext[3]} content={content3} />

                  </TabItemsDirective>
                  
              </TabComponent>

              </div>
              </div>


            </div>

            {/* </div> */}
            
        </>
    );
};

export default LearningCenter;

