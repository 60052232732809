import React from "react";
import "../../assets/custom-stylesheet/app2_style.css";
import "../../assets/custom-stylesheet/samplepage_style.css";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import Calculators from "../calculators/Calculators";
import { useHistory } from 'react-router-dom';

const CalculatorGrid = () => {

    const history = useHistory();

    return (
        <>
            <div className="mt-2 container-fluid">
                <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
                <div className="d-flex">
                    
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '14px', color:'blue' }} onClick={() => history.push('/dashboard/mold')} > Molds </span>
                    </div>
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '16px' }}> {">"} </span>
                    </div>
                    <div>
                        <span className="BreadCrum" style={{ fontSize: '14px' }}> Calculators </span>
                    </div>
                </div>
                </div>
            </div>
            <div id="Container" className="container-fluid">
                <div className="d-flex justify-content-start">
                <Calculators />
                </div>
            </div>
        </>
    );
};

export default CalculatorGrid;

