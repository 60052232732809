import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Table } from "reactstrap";
import DataService from "../../services/ApiService";

const ManageSessionsTable = ({ SessionData, ModalStates, ToggleManageSessionModal, setSessionData }) => {

    const [OperationsModal, setOperationsModal] = useState({
        Edit: false,
        Delete: false
    })

    const [SelectedRow, setSelectedRow] = useState(null)

    const ManageOperation = (Operation) => {

        // console.log(SelectedRow)

        if (Operation === 'Edit') {

            setOperationsModal(prevState => ({
                ...prevState,
                Edit: !OperationsModal.Edit,
            }))

        }
        else {

            setOperationsModal(prevState => ({
                ...prevState,
                Delete: !OperationsModal.Delete,
            }))

        }

    }

    const PerformOperation = (Operation) => {

        // console.log(SelectedRow)

        if (Operation === 'Edit') {



        }
        else {

            DataService.DeleteSession(SelectedRow.id).then(() => {

                setOperationsModal(prevState => ({
                    ...prevState,
                    Delete: !OperationsModal.Delete,
                }))

                ToggleManageSessionModal()

                setSessionData(prevData => ({
                    ...prevData,
                    [ModalStates.ManageSessions.SessionName]: SessionData.filter((data) => {
                        return data.id != SelectedRow.id
                    })
                }))

            })

        }

    }

    return (
        <>

            <Modal isOpen={OperationsModal.Edit} centered={true} style={{ width: "300px" }}>
                <ModalHeader toggle={() => ManageOperation('Edit')}> Nautilus </ModalHeader>
                <ModalBody>
                    <span> Session with this name already exists. </span>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" onClick={() => ManageOperation('Edit')}>
                        {" "}
                        Close{" "}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={OperationsModal.Delete} centered={true} style={{ width: "350px" }}>
                <ModalHeader toggle={() => ManageOperation('Delete')}> Nautilus </ModalHeader>
                <ModalBody>
                    <span> <strong> Are you sure, you want to delete this session ? </strong> </span>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" onClick={() => PerformOperation('Delete')}>
                        {" "}
                        Yes{" "}
                    </Button>
                    <Button color="dark" onClick={() => ManageOperation('Delete')}>
                        {" "}
                        No{" "}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={ModalStates.ManageSessions.visibility} centered={true} style={{ width: "500px" }}>
                <ModalHeader> Session details </ModalHeader>
                <ModalBody>

                    <div>

                        <div style={{ border: "1px solid black", width: "450px" }}>
                            <Table
                                id="Custom_Fields_Table"
                                className="table-responsive"
                                width={300}
                                height={200}
                                cellPadding={0}
                                cellSpacing={0}
                            >
                                <thead>
                                    <tr>
                                        <th align="center" style={{ width: "50px" }}>

                                        </th>
                                        <th align="center" style={{ width: "200px" }}>
                                            Name
                                        </th>
                                        <th align="center" style={{ width: "200px" }}>
                                            Date
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {SessionData ? SessionData.map((Data, Key) => (
                                        <tr key={Key}>
                                            <td align="left" style={{ background: "#fff" }}>
                                                <input
                                                    type="radio"
                                                    className="form-control"
                                                    onChange={() => setSelectedRow(Data)}
                                                />{" "}
                                            </td>
                                            <td align="left" style={{ background: "#fff" }}>
                                                <span
                                                    className="form-control b-b-primary"
                                                    style={{ width: "200px", textAlign: "center" }}
                                                > <strong> {Data.Session_Name} </strong> </span>
                                            </td>
                                            <td align="left" style={{ background: "#fff" }}>
                                                <span
                                                    className="form-control b-b-primary"
                                                    style={{ width: "200px", textAlign: "center" }}
                                                > <strong> {Data.Date} </strong> </span>
                                            </td>
                                        </tr>
                                    )) : <></>}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    {/* <Button color="warning"
                        onClick={() => ManageOperation("Edit")}
                    >
                        {" "}
                        Edit{" "}
                    </Button> */}
                    <Button
                        color="warning"
                        onClick={() => ManageOperation("Delete")}
                    >
                        {" "}
                        Delete{" "}
                    </Button>
                    <Button color="primary"
                        onClick={ToggleManageSessionModal}
                    >
                        {" "}
                        Close{" "}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ManageSessionsTable;