import React from "react";

const Outputs = ({ PPS_Data, Machine_Unit_Data }) => {

  const CreateInputData = (event) => {

    const { name, value, dataset } = event.target;

    let data = {

      paramID: dataset.id,
      paramName: name,
      paramUnit: dataset.section === "weight" ? Machine_Unit_Data.weight.unit_id : dataset.section === "press" ? Machine_Unit_Data.pressure.unit_id : dataset.section === "time" ? Machine_Unit_Data.time.unit_id : dataset.section === "temperature" ? Machine_Unit_Data.temperature.unit_id : "",
      paramValue: value,

    }

    // console.log(data)

    PPS_Data.Outputs[0][name] = data

  }

  return (
    <div
      className="p-2"
      style={{ border: "1px solid black", backgroundColor: "#d2dce6" }}
    >
      <div
        className="d-flex flex-column align-items-left"
        style={{ width: "30vw" }}
      >
        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">1.</span>
            <span>Melt Temperature:</span>
          </div>
          <div>
            <input className="form-control" data-id={"55"} data-section={"temperature"} name="fldMeltTemp" onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldMeltTemp?.paramValue} />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">2.</span>
            <span>Mold Temp:</span>
          </div>
          <div>
            <input className="form-control" data-id={"56"} data-section={"temperature"} name="fldMoldTemp" onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldMoldTemp?.paramValue} />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">3.</span>
            <span>Fill Time*:</span>
          </div>
          <div>
            <input className="form-control" data-id={"57"} data-section={"time"} name="fldFillTime" onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldFillTime?.paramValue} />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">4.</span>
            <span>Actual Peak Injection Pressure*:</span>
          </div>
          <div>
            <input className="form-control" data-id={"58"} data-section={"press"} name="fldInjPressPeak" onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldInjPressPeak?.paramValue} />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">5.</span>
            <span>Press At Transfer:</span>
          </div>
          <div>
            <input className="form-control" data-id={"59"} name="fldInjPressTransfer" onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldInjPressTransfer?.paramValue} />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">6.</span>
            <span>Cushion Value:</span>
          </div>
          <div>
            <input className="form-control" data-id={"60"} name="fldCushionValue" onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldCushionValue?.paramValue} />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">7.</span>
            <span>Screw Rotation Time:</span>
          </div>
          <div>
            <input className="form-control" data-id={"61"} name="fldScrewRotationTime" onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldScrewRotationTime?.paramValue} />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">8.</span>
            <span>Cycle Time:</span>
          </div>
          <div>
            <input className="form-control" data-id={"62"} data-section={"time"} name="fldCycleTime" onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldCycleTime?.paramValue} />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">9.</span>
            <span>Inj Only Shot Weight:</span>
          </div>
          <div>
            <input className="form-control" data-id={"63"} data-section={"weight"} name="fldInjOnlyShotWeight" onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldInjOnlyShotWeight?.paramValue} />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">10.</span>
            <span>Part and Runner Weight:</span>
          </div>
          <div>
            <input className="form-control" data-id={"64"} name="fldPartsAndRunnerWeight" onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldPartsAndRunnerWeight?.paramValue} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Outputs;
