import React, { useState } from 'react';
import { connect } from 'react-redux';
import { resetPasswordConfirm } from '../actions/auth';

const ResetPwdConfirm = ({ resetPasswordConfirm, match }) => {

    const [show1, setShow1] = useState(false);

    const [formData, setFormData] = useState({
        password: '',
        re_password: ''
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const { password, re_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        const uid = match.params.uid;
        const token = match.params.token;

        resetPasswordConfirm(uid, token, password, re_password);

        setShow1(true)

        setTimeout(() => {
            window.location = `${process.env.REACT_APP_API_URL}/login`
        }, 500)
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">

                                        <div className="card mt-4" style={{ width: '90%' }}>
                                            {show1 && <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                Password has been successfully changed. Please close this page and go back to logIn page.
                                                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShow1(false)}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>}
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h5 className="card_head"> Create New Password </h5>
                                                </div>
                                                <form className="theme-form" onSubmit={e => onSubmit(e)}>
                                                    <div className='pb-3'>
                                                        <span style={{ color: 'red' }}> Note:- Password should contain a minimum of 8 characters and a combination of alphabets, numbers and special characters. </span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label> Password: </label>
                                                        <div className="d-flex mt-2">

                                                            <input className="form-control" placeholder="Make a password using alphabets and number" onChange={e => onChange(e)} value={password} minLength={8} name="password" type={showPassword ? "text" : "password"} required />

                                                            <i className="far fa-eye m-2" style={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)}></i>

                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0"> Retype New Password </label>
                                                        <div className="d-flex">

                                                            <input className="form-control" placeholder="Make a password using alphabets and number" type={showPassword2 ? "text" : "password"} minLength={8} onChange={e => onChange(e)} value={re_password} name="re_password" required />

                                                            <i className="far fa-eye m-2" style={{ cursor: "pointer" }} onClick={() => setShowPassword2(!showPassword2)}></i>

                                                        </div>
                                                    </div>

                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block" type="submit"> Confirm Reset Password </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(null, { resetPasswordConfirm })(ResetPwdConfirm);
