import React from "react";
import ExperimentalRunSettings from "./ExperimentalRunSettings";
import DataInputWorksheets from "./DataInputWorksheet";
import "../../App.css"


const EditExptData = ({ mold }) => {
  console.log(mold)
  return (

    <div className="b-primary b-r-4 p-2">

      <div>

        <div className="ml-2">
          <h6> <b> Experimental Run Settings </b> </h6>
        </div>

        <div><ExperimentalRunSettings /></div>

      </div>

      <div>

        <div className="ml-2"><h6> <b> Experimental Data Input Worksheets </b> </h6></div>
        <div className="ml-2"><h6> <b> Responses </b> </h6></div>

        <div><DataInputWorksheets mold={mold} /></div>

      </div>

    </div>
  );
};

export default EditExptData;
