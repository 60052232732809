import React from "react";

const FixedBottom = ({
  Machine_Unit_Data,
  handlePrint,
  HandleUpdateData,
  ShowSaveButton,
  handleSave,
  handleClose,
}) => {
  
  return (
    <>
      <span>Pressure: <strong className="ml-3"> {Machine_Unit_Data.pressure.unit_name} </strong> </span>
      <span>Temp: <strong className="ml-3">{Machine_Unit_Data.temperature.unit_name}</strong> </span>
      <span>Distance: <strong className="ml-3">{Machine_Unit_Data.distance.unit_name}</strong> </span>
      <span>Time: <strong className="ml-3">{Machine_Unit_Data.time.unit_name}</strong> </span>
      <span>Velocity: <strong className="ml-3">{Machine_Unit_Data.velocity.unit_name}</strong> </span>
      <span>Weight: <strong className="ml-3">{Machine_Unit_Data.weight.unit_name}</strong> </span>
      <div className="buttonGroup">
        {/* <button
          className="btn btn-secondary btn-air-secondary ml-3"
          onClick={handlePrint}
        >
          Print
        </button> */}
        <button className="btn btn-primary btn-air-primary ml-3" onClick={ShowSaveButton ? handleSave : HandleUpdateData}>
          Save
        </button>
        <button
          className="btn btn-warning btn-air-warning ml-3"
          onClick={handleClose}
        >
          Close
        </button>
      </div>
    </>
  );
};

export default FixedBottom;
