import React, { useState } from "react";
import "../assets/custom-stylesheet/login_style.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { resetLogIn } from "../actions/auth";

const ResetLogIn = ({ resetLogIn }) => {
  const [show1, setShow1] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(null);

  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    const res = await resetLogIn(email);

    setErrorMessage(res);

    setShow1(true);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="card mt-4" style={{ width: "90%" }}>
                      {show1 && (
                        <div
                          className={
                            ErrorMessage.includes("not")
                              ? "alert alert-danger alert-dismissible fade show"
                              : "alert alert-success alert-dismissible fade show"
                          }
                          role="alert"
                        >
                          {ErrorMessage}.
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setShow1(false)}
                          >
                            <span aria-hidden="true" className="pl-1">
                              &times;
                            </span>
                          </button>
                        </div>
                      )}
                      <div className="card-body">
                        <div className="text-center">
                          <h5 className="card_head"> Reset LogIn Status </h5>
                        </div>
                        <form
                          className="theme-form"
                          onSubmit={(e) => onSubmit(e)}
                        >
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              {" "}
                              Email{" "}
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              value={email}
                              onChange={(e) => onChange(e)}
                              placeholder="Please Enter Your Registered Email Address"
                              required
                            />
                          </div>

                          <div className="form-group form-row mt-3 mb-0">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              {" "}
                              Send Reset Link{" "}
                            </button>
                          </div>

                          <div className="login_links text-center">
                            <div className="mt-2">
                              <Link
                                className="btn-link text-capitalize sign_in"
                                to="/login"
                              >
                                {" "}
                                Back to Log In{" "}
                              </Link>
                            </div>
                          </div>

                          <div className="login_links text-center">
                            <div className="mt-2">
                              {" "}
                              Don't have an Account ? 
                              <Link
                                className="btn-link text-capitalize sign_in"
                                to="/signup"
                              >
                                {" "}
                                Sign Up{" "}
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { resetLogIn })(ResetLogIn);
