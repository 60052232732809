import React, { useEffect, useRef, useState } from "react";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { useHistory, useParams } from "react-router-dom";
import Header2 from "../common/header-component/header2";
import MachineInformation from "./MachineInformation/MachInfo";
import Inputs from "./Inputs/Inputs";
import Outputs from "./Outputs/Outputs";
import HR from "./HR/HR";
import Notes from "./Notes/Notes";
import "./PPS.css";
import {
  Units,
  HRSett,
  PHTime,
  PHPress,
  PHVeloc,
  InjPress,
  InjSpeed,
  fldShotSize,
  fldBackPress,
  Decompression,
  fldCoolingTime,
  fldObtainedCushion,
  fldScrewRotationSpeed,
  TransferPosition,
  BarrelTempSetting,
  Output,
  MoldTempASide,
  MoldTempBSide,
  MoldTempNotes,
} from "./DataObjects";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import FixedBottom from "./FixedBottom/FixedBottom";
import DataService from "../../services/ApiService";

const PPS = () => {

  const StoredUnits = JSON.parse(sessionStorage.getItem("StoredUnits"));
  const SelectedUnits = JSON.parse(sessionStorage.getItem("SelectedUnits"));

  const [ModalStates, setModalStates] = useState({
    PositionDataAlert: {
      visibility: false,
    }
  })

  // Event to close the dialog which will be shown after saving
  const ShowSavedDialog = () => {
    const saveddialogBox = document.getElementById("SaveddialogBox");

    saveddialogBox.classList.remove("hidden");
  };

  const CloseSavedDialog = () => {
    const saveddialogBox = document.getElementById("SaveddialogBox");

    saveddialogBox.classList.add("hidden");
  };

  const ShowImpFieldsdialogBox = () => {
    const saveddialogBox = document.getElementById("ImpFieldsdialogBox");

    saveddialogBox.classList.remove("hidden");
  };

  const CloseImpFieldsdialogBox = () => {
    const saveddialogBox = document.getElementById("ImpFieldsdialogBox");

    saveddialogBox.classList.add("hidden");
  };

  const TogglePositionDataState = () => {

    setModalStates((prevState) => ({
      ...prevState,
      PositionDataAlert: {
        ...prevState.PositionDataAlert,
        visibility: !ModalStates.PositionDataAlert.visibility
      }
    }))

  }

  const MachineData =
    JSON.parse(sessionStorage.getItem("MachineData")).length > 0
      ? JSON.parse(sessionStorage.getItem("MachineData"))
      : [];

  let AllMoldData =
    JSON.parse(sessionStorage.getItem("MoldData")).length > 0
      ? JSON.parse(sessionStorage.getItem("MoldData"))
      : [];

  let SelectedMold =
    JSON.parse(sessionStorage.getItem("SelectedMoldData")).length > 0
      ? JSON.parse(sessionStorage.getItem("SelectedMoldData"))
      : [];

  const [MoldData, setMoldData] = useState([AllMoldData[0]]);

  const [UserSelectedUnits, setUserSelectedUnits] = useState([]);
  const [BaseUnits, setBaseUnits] = useState([]);
  const [BaseUnitsArray, setBaseUnitsArray] = useState([]);

  const [Machine_Unit_Data, setMachine_Unit_Data] = useState({
    pressure: {
      unit_id: "",
      unit_name: "",
      decimals: "",
    },
    temperature: {
      unit_id: "",
      unit_name: "",
      decimals: "",
    },
    distance: {
      unit_id: "",
      unit_name: "",
      decimals: "",
    },
    time: {
      unit_id: "",
      unit_name: "",
      decimals: "",
    },
    velocity: {
      unit_id: "",
      unit_name: "",
      decimals: "",
    },
    weight: {
      unit_id: "",
      unit_name: "",
      decimals: "",
    },
  });

  const UpdateUnitData = (event) => {
    const { name, value } = event.target;

    setGeneralInfo({
      ...GeneralInfo,
    });

    if (name === "pressure") {
      setMachine_Unit_Data((prevData) => ({
        ...prevData,
        pressure: {
          ...prevData.pressure,
          unit_id: parseInt(value),
          unit_name: BaseUnits
            ? BaseUnits.find((unit) => unit.unit_id === parseInt(value))
              .unit_name
            : "",
        },
      }));
    }
    if (name === "distance") {
      setMachine_Unit_Data((prevData) => ({
        ...prevData,
        distance: {
          ...prevData.distance,
          unit_id: parseInt(value),
          unit_name: BaseUnits
            ? BaseUnits.find((unit) => unit.unit_id === parseInt(value))
              .unit_name
            : "",
        },
      }));
    }
    if (name === "temperature") {
      setMachine_Unit_Data((prevData) => ({
        ...prevData,
        temperature: {
          ...prevData.temperature,
          unit_id: parseInt(value),
          unit_name: BaseUnits
            ? BaseUnits.find((unit) => unit.unit_id === parseInt(value))
              .unit_name
            : "",
        },
      }));
    }
    if (name === "velocity") {
      setMachine_Unit_Data((prevData) => ({
        ...prevData,
        velocity: {
          ...prevData.velocity,
          unit_id: parseInt(value),
          unit_name: BaseUnits
            ? BaseUnits.find((unit) => unit.unit_id === parseInt(value))
              .unit_name
            : "",
        },
      }));
    }
    if (name === "time") {
      setMachine_Unit_Data((prevData) => ({
        ...prevData,
        time: {
          ...prevData.time,
          unit_id: parseInt(value),
          unit_name: BaseUnits
            ? BaseUnits.find((unit) => unit.unit_id === parseInt(value))
              .unit_name
            : "",
        },
      }));
    }
    if (name === "weight") {
      setMachine_Unit_Data((prevData) => ({
        ...prevData,
        weight: {
          ...prevData.weight,
          unit_id: parseInt(value),
          unit_name: BaseUnits
            ? BaseUnits.find((unit) => unit.unit_id === parseInt(value))
              .unit_name
            : "",
        },
      }));
    }
  };

  const [Mold_Machine_Info, setMold_Machine_Info] = useState({
    SelectedMold: null,
    SelectedMachine: {
      id: 0,
    },
  });

  const [ShowSaveButton, setShowSaveButton] = useState(true);

  const UpdateMachineData = (e) => {
    let MachId = parseInt(e.target.value);

    setGeneralInfo({
      ...GeneralInfo,
    });

    setMold_Machine_Info({
      ...Mold_Machine_Info,
      SelectedMachine: {
        id: MachId,
        IR: MachineData.find((data) => data.id === MachId).Machine_Data
          .Intensification_Ratio,
        SD: MachineData.find((data) => data.id === MachId).Machine_Units_Data
          .Screw_Diameter.value,
        SD_Unit:
          UserSelectedUnits.Distance && BaseUnits
            ? BaseUnits.find(
              (unit) =>
                unit.unit_id ===
                parseInt(
                  MachineData.find((data) => data.id === MachId)
                    .Machine_Units_Data.Screw_Diameter.unit_id
                )
            ).unit_name
            : "",
      },
    });
  };

  let [GeneralInfo, setGeneralInfo] = useState({
    user: "",
    Session_Name: "",
    Session_Date: "",
    Session_Notes: "",
  });

  const GetGeneralInfo = (e) => {
    const { name, value } = e.target;

    GeneralInfo = {
      ...GeneralInfo,
      [name]: value,
    };
  };

  let [PPS_Data, setPPS_Data] = useState({
    Units: Units,
    HRSett: HRSett,
    Inputs: [
      {
        PHTime: [PHTime],
        PHPress: [PHPress],
        PHVeloc: [PHVeloc],
        InjPress: [InjPress],
        InjSpeed: [InjSpeed],
        Decompression: [Decompression],
        MoldTempASide: [MoldTempASide],
        MoldTempBSide: [MoldTempBSide],
        MoldTempNotes: [MoldTempNotes],
        fldCoolingTime: fldCoolingTime,
        TransferPosition: [TransferPosition],
        BarrelTempSetting: [BarrelTempSetting],
        fldObtainedCushion: fldObtainedCushion,
        fldShotSize: fldShotSize,
        fldBackPress: fldBackPress,
        fldScrewRotationSpeed: fldScrewRotationSpeed,
      },
    ],
    Notes: "",
    Outputs: [Output],
  })

  const history = useHistory();

  let HeaderData = [
    { text: "General Information" },
    { text: "Inputs" },
    { text: "HR" },
    { text: "Outputs" },
    { text: "Notes" },
  ];

  const [ProjectData, setProjectData] = useState({
    MoldId: "",
    MoldName: "",
    sessionId: "",
    sessionName: "",
  });

  var { sessionId, sessionName, moldId, moldName } = useParams();

  const BackToMold = () => {
    history.push(`/mold`);
  };

  const BackToDashboard = () => {
    history.push("/dashboard");
  };

  const handlePrint = () => {
    window.print();
  };

  const handleSave = () => {
    let data = {
      ...GeneralInfo,
      VPS_Data: {
        ...PPS_Data,
        Units: {
          TimeUnit: Machine_Unit_Data.time.unit_id,
          WeightUnit: Machine_Unit_Data.weight.unit_id,
          DistanceUnit: Machine_Unit_Data.distance.unit_id,
          PressureUnit: Machine_Unit_Data.pressure.unit_id,
          VelosityUnit: Machine_Unit_Data.velocity.unit_id,
          TemperatureUnit: Machine_Unit_Data.temperature.unit_id,
        },
      },
      Mold_Id: MoldData.length > 0 ? MoldData[0].id : "",
      session_id: ProjectData.sessionId,
      Machine_Id: Mold_Machine_Info.SelectedMachine.id,
      Mold_Name: MoldData.length > 0 ? MoldData[0].Mold_Id : "",
    }

    let MandatoryFields = [
      parseFloat(data.VPS_Data.Inputs[0].fldObtainedCushion.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition1.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].fldShotSize.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].PHPress[0].fldPHPress6?.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed5?.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].fldScrewRotationSpeed?.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].fldBackPress?.paramValue)
    ]

    // console.log(data.VPS_Data.Inputs)

    let isFieldsEmpty = MandatoryFields.some((value) =>
      isNaN(value)
    )

    // console.log(MandatoryFields, isFieldsEmpty)

    // console.log(parseFloat(data.VPS_Data.Inputs[0].fldObtainedCushion.paramValue),
    //   parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition1.paramValue), parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition2.paramValue), parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition3.paramValue), parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition4.paramValue), parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition5.paramValue),
    //   parseFloat(data.VPS_Data.Inputs[0].fldShotSize.paramValue))

    if (!isFieldsEmpty) {

      if (
        parseFloat(data.VPS_Data.Inputs[0].fldObtainedCushion.paramValue) < parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition1.paramValue) &&
        parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition1.paramValue) < parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition5.paramValue) &&
        parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition5.paramValue) < parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition4.paramValue) &&
        parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition4.paramValue) < parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition3.paramValue) &&
        parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition3.paramValue) < parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition2.paramValue) &&
        parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition2.paramValue) < parseFloat(data.VPS_Data.Inputs[0].fldShotSize.paramValue)
      ) {

        DataService.SavePPSData(data).then((res) => {

          ShowSavedDialog()

          setShowSaveButton(false)

        })

      }
      else {
        TogglePositionDataState()
      }

    }
    else {

      ShowImpFieldsdialogBox()

    }

  }

  const HandleGetData = (session_id) => {

    DataService.FetchPPSData(session_id).then((res) => {

      // console.log(res)

      if (res.data != "") {

        setGeneralInfo({
          user: res.data.user,
          Session_Name: res.data.Session_Name,
          Session_Date: res.data.Session_Date,
          Session_Notes: res.data.Session_Notes,
        })

        setPPS_Data(res.data.VPS_Data)

        let SavedUnits = res.data.VPS_Data.Units

        setMachine_Unit_Data((prevData) => ({
          pressure: {
            ...prevData.pressure,
            unit_id: parseInt(SavedUnits.PressureUnit),
            unit_name: BaseUnits && BaseUnits.find((unit) => unit.unit_id === parseInt(SavedUnits.PressureUnit))?.unit_name || "",
          },
          distance: {
            ...prevData.distance,
            unit_id: parseInt(SavedUnits.DistanceUnit),
            unit_name: BaseUnits && BaseUnits.find((unit) => unit.unit_id === parseInt(SavedUnits.DistanceUnit))?.unit_name || "",
          },
          temperature: {
            ...prevData.temperature,
            unit_id: parseInt(SavedUnits.TemperatureUnit),
            unit_name: BaseUnits && BaseUnits.find((unit) => unit.unit_id === parseInt(SavedUnits.TemperatureUnit))?.unit_name || "",
          },
          velocity: {
            ...prevData.velocity,
            unit_id: parseInt(SavedUnits.VelocityUnit),
            unit_name: BaseUnits && BaseUnits.find((unit) => unit.unit_id === parseInt(SavedUnits.VelocityUnit))?.unit_name || "",
          },
          time: {
            ...prevData.time,
            unit_id: parseInt(SavedUnits.TimeUnit),
            unit_name: BaseUnits && BaseUnits.find((unit) => unit.unit_id === parseInt(SavedUnits.TimeUnit))?.unit_name || "",
          },
          weight: {
            ...prevData.weight,
            unit_id: parseInt(SavedUnits.WeightUnit),
            unit_name: BaseUnits && BaseUnits.find((unit) => unit.unit_id === parseInt(SavedUnits.WeightUnit))?.unit_name || "",
          },
        }));

        setShowSaveButton(false)

      }

    })

  }

  useEffect(() => {

    // console.log( Machine_Unit_Data )

  }, [Machine_Unit_Data])

  const HandleUpdateData = () => {

    let data = {
      ...GeneralInfo,
      VPS_Data: {
        ...PPS_Data,
        Units: {
          TimeUnit: Machine_Unit_Data.time.unit_id,
          WeightUnit: Machine_Unit_Data.weight.unit_id,
          DistanceUnit: Machine_Unit_Data.distance.unit_id,
          PressureUnit: Machine_Unit_Data.pressure.unit_id,
          VelosityUnit: Machine_Unit_Data.velocity.unit_id,
          TemperatureUnit: Machine_Unit_Data.temperature.unit_id,
        },
      },
      Mold_Id: MoldData.length > 0 ? MoldData[0].id : "",
      session_id: ProjectData.sessionId,
      Machine_Id: Mold_Machine_Info.SelectedMachine.id,
      Mold_Name: MoldData.length > 0 ? MoldData[0].Mold_Id : "",
    }

    let MandatoryFields = [
      parseFloat(data.VPS_Data.Inputs[0].fldObtainedCushion.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition1.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].fldShotSize.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].PHPress[0].fldPHPress6?.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed5?.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].fldScrewRotationSpeed?.paramValue),
      parseFloat(data.VPS_Data.Inputs[0].fldBackPress?.paramValue)
    ]

    console.log(data.VPS_Data.Outputs)

    let isFieldsEmpty = MandatoryFields.some((value) =>
      isNaN(value)
    )

    // console.log(MandatoryFields, isFieldsEmpty)

    // console.log(parseFloat(data.VPS_Data.Inputs[0].fldObtainedCushion.paramValue),
    //   parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition1.paramValue),
    //   parseFloat(data.VPS_Data.Inputs[0].fldShotSize.paramValue))

    if (!isFieldsEmpty) {

      if (
        parseFloat(data.VPS_Data.Inputs[0].fldObtainedCushion.paramValue) < parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition1.paramValue) &&
        parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition1.paramValue) < parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition5.paramValue) &&
        parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition5.paramValue) < parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition4.paramValue) &&
        parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition4.paramValue) < parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition3.paramValue) &&
        parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition3.paramValue) < parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition2.paramValue) &&
        parseFloat(data.VPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition2.paramValue) < parseFloat(data.VPS_Data.Inputs[0].fldShotSize.paramValue)) {

        DataService.UpdatePPSData(ProjectData.sessionId, data).then((res) => {

          // console.log( res.data.data )

          if (res.data.Status === 200) {

            setGeneralInfo({
              user: res.data.data.user,
              Session_Name: res.data.data.Session_Name,
              Session_Date: res.data.data.Session_Date,
              Session_Notes: res.data.data.Session_Notes,
            })

            setPPS_Data(res.data.data.VPS_Data)

            ShowSavedDialog()

          }

        })

      }
      else {
        TogglePositionDataState()
      }

    }
    else {

      ShowImpFieldsdialogBox()

    }

  }

  const handleClose = () => {
    window.close();
  };

  const content0 = () => {
    return (
      <div style={{ height: "80vh" }}>
        <div className="card p-2" style={{ border: "2px solid #000" }}>
          <MachineInformation
            Machine_Unit_Data={Machine_Unit_Data}
            MachineData={MachineData}
            BaseUnitsArray={BaseUnitsArray}
            UpdateUnitData={UpdateUnitData}
            Mold_Machine_Info={Mold_Machine_Info}
            UpdateMachineData={UpdateMachineData}
            MoldData={MoldData}
            ProjectData={ProjectData}
            GetGeneralInfo={GetGeneralInfo}
            GeneralInfo={GeneralInfo}
            PPS_Data={PPS_Data}
          />
        </div>
      </div>
    );
  };

  const content1 = () => {
    return (
      <div style={{ height: "80vh" }}>
        <div className="card p-2" style={{ border: "2px solid #000" }}>
          <Inputs
            Machine_Unit_Data={Machine_Unit_Data}
            Mold_Machine_Info={Mold_Machine_Info}
            PPS_Data={PPS_Data}
          />
        </div>
      </div>
    );
  };

  const content2 = () => {
    return (
      <div style={{ height: "80vh" }}>
        <div className="card p-2 mb-2" style={{ border: "2px solid #000" }}>
          <HR PPS_Data={PPS_Data} />
        </div>
      </div>
    );
  };

  const content3 = () => {
    return (
      <div className="card p-2 mb-2" style={{ border: "2px solid #000" }}>
        <Notes PPS_Data={PPS_Data} />
      </div>
    );
  };

  const content4 = () => {
    return (
      <div className="card p-2 mb-2" style={{ border: "2px solid #000" }}>
        <Outputs PPS_Data={PPS_Data}
          Machine_Unit_Data={Machine_Unit_Data}
        />
      </div>
    );
  };

  useEffect(() => {
    setMoldData(
      AllMoldData.filter((data) => {
        return data.id === SelectedMold[0].id;
      })
    );

    setProjectData({
      MoldId: atob(moldId),
      MoldName: atob(moldName),
      sessionId: atob(sessionId),
      sessionName: atob(sessionName),
    });
  }, [moldId, moldName, sessionId, sessionName]);

  useEffect(() => {

    if (ProjectData.sessionId) {

      HandleGetData(ProjectData.sessionId)

    }

  }, [ProjectData])

  useEffect(() => {
    let allUnits = [];

    // Iterate through each category in the data
    for (const category in StoredUnits.Unit_Data[0]) {
      // Add each unit object to the allUnits array
      allUnits = allUnits.concat(StoredUnits.Unit_Data[0][category]);
    }

    setUserSelectedUnits(SelectedUnits.Unit_Data[0]);

    setBaseUnits(allUnits);
    setBaseUnitsArray(StoredUnits.Unit_Data[0]);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let no_of_cavities = 0;

    if (MoldData.length > 0) {
      no_of_cavities = MoldData.reduce((total, data) => {
        // Loop through each key in Part_Data[2] and sum only "Part" keys
        return (
          total +
          Object.keys(data.Part_Data[2]).reduce((sum, key) => {
            // Check if the key starts with "Part" and then parse the value
            if (key.startsWith("Part")) {
              return sum + parseInt(data.Part_Data[2][key], 10) || 0;
            }
            return sum;
          }, 0)
        );
      }, 0);
    }

    setMold_Machine_Info({
      SelectedMoldTotalCavities: no_of_cavities,
      SelectedMachine:
        MachineData.length > 0
          ? {
            id: MachineData[0].id,
            IR: MachineData[0].Machine_Data.Intensification_Ratio,
            SD: MachineData[0].Machine_Units_Data.Screw_Diameter.value,
            SD_Unit:
              UserSelectedUnits.Distance && BaseUnits
                ? BaseUnits.find(
                  (unit) =>
                    unit.unit_id ===
                    parseInt(
                      MachineData[0].Machine_Units_Data.Screw_Diameter
                        .unit_id
                    )
                ).unit_name
                : "",
          }
          : {},
    });

    setMachine_Unit_Data({
      ...Machine_Unit_Data,
      pressure: {
        ...Machine_Unit_Data.pressure,
        unit_id:
          UserSelectedUnits.Pressure && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Pressure.unit_id
            ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Pressure && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Pressure.unit_id
            ).unit_name
            : "",
        decimals: UserSelectedUnits.Pressure
          ? UserSelectedUnits.Pressure.decimals
          : 0,
      },

      temperature: {
        ...Machine_Unit_Data.temperature,
        unit_id:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
            ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Temperature && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Temperature.unit_id
            ).unit_name
            : "",
        decimals: UserSelectedUnits.Temperature
          ? UserSelectedUnits.Temperature.decimals
          : 0,
      },

      distance: {
        ...Machine_Unit_Data.distance,
        unit_id:
          UserSelectedUnits.Distance && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
            ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Distance && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Distance.unit_id
            ).unit_name
            : "",
        decimals: UserSelectedUnits.Distance
          ? UserSelectedUnits.Distance.decimals
          : 0,
      },

      time: {
        ...Machine_Unit_Data.time,
        unit_id:
          UserSelectedUnits.Time && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Time.unit_id
            ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Time && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Time.unit_id
            ).unit_name
            : "",
        decimals: UserSelectedUnits.Time ? UserSelectedUnits.Time.decimals : 0,
      },

      velocity: {
        ...Machine_Unit_Data.velocity,
        unit_id:
          UserSelectedUnits.Speed && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Speed.unit_id
            ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Speed && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Speed.unit_id
            ).unit_name
            : "",
        decimals: UserSelectedUnits.Speed
          ? UserSelectedUnits.Speed.decimals
          : 0,
      },

      weight: {
        ...Machine_Unit_Data.weight,
        unit_id:
          UserSelectedUnits.Weight && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
            ).unit_id
            : "",
        unit_name:
          UserSelectedUnits.Weight && BaseUnits
            ? BaseUnits.find(
              (unit) => unit.unit_id === UserSelectedUnits.Weight.unit_id
            ).unit_name
            : "",
        decimals: UserSelectedUnits.Weight
          ? UserSelectedUnits.Weight.decimals
          : 0,
      },
    });
    // eslint-disable-next-line
  }, [UserSelectedUnits, BaseUnitsArray]);

  return (
    <>
      <Header2 Title="Plastic Process Sheet" />

      {/* To show when data is saved, when clicked on saved button */}
      <div id="SaveddialogBox" className="hidden">
        <div>
          <h5> Nautilus </h5>
        </div>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={CloseSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved, when clicked on saved button */}
      <div id="ImpFieldsdialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">

          <p style={{ textAlign: "left", whiteSpace: "pre-line" }}>
            <span>Please Note:</span>{"\n"}
            <span>
              Fields marked with * are mandatory. Please fill the values and continue to Save.
            </span>{"\n"}
            <span>
              <strong> Mandatory fields are: </strong> {"\n"}
              <span>
                <strong> Machine Information </strong> {"\n"}
                &gt; Machine Number{"\n"}
                &gt; Unit/s{"\n"}
                <strong> Inputs </strong> {"\n"}
                &gt; Shot Size{"\n"}
                &gt; Transfer{"\n"}
                &gt; Cushion{"\n"}
                &gt; P/H Press 1{"\n"}
                &gt; Inj. Vel 1{"\n"}
                &gt; Screw Rotation Speed{"\n"}
                &gt; Back Press{"\n"}
                <strong> Outputs </strong> {"\n"}
                &gt; Fill Time{"\n"}
                &gt; Act. Peak Inj. Press
              </span>
            </span>
          </p>

          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={CloseImpFieldsdialogBox}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      <Modal isOpen={ModalStates.PositionDataAlert.visibility} centered={true} toggle={TogglePositionDataState}>
        <ModalHeader toggle={TogglePositionDataState}> Nautilus </ModalHeader>
        <ModalBody>
          <div>

            <span className="mb-2">
              The values should be in descending order from the shot size to the cushion.
            </span>
            <br></br>
            <span>
              Cushion {"<"} Transfer {"<"} Posn4 {"<"} Posn3 {"<"} Posn2 {"<"} Posn2 {"<"} Shot Size
            </span>

          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="fourth" onClick={TogglePositionDataState}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex ">
            <div>
              <span
                className="BreadCrum"
                style={{ fontSize: "14px" }}
                onClick={BackToDashboard}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>

            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span
                className="BreadCrum"
                style={{ fontSize: "14px" }}
                onClick={BackToMold}
              >
                {" "}
                Mold{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "14px" }}>
                {" "}
                Plastic Process Sheet{" "}
              </span>
            </div>
          </div>

          <div className="d-flex b-primary b-r-4 pt-1">
            <div className="ml-4 mr-2">
              <span className="Mold-Session"> Session Name : </span>
              <span className="Mold-Session">{ProjectData.sessionName}</span>
            </div>
          </div>
        </div>
      </div>

      <div>

        <div id="PPS_Tabs">
          <TabComponent
            heightAdjustMode="Auto"
            widthAdjustMode="Auto"
            id="defaultTab"
          >
            <TabItemsDirective>
              <TabItemDirective header={HeaderData[0]} content={content0} />
              <TabItemDirective header={HeaderData[1]} content={content1} />
              <TabItemDirective header={HeaderData[2]} content={content2} />
              <TabItemDirective header={HeaderData[3]} content={content4} />
              <TabItemDirective header={HeaderData[4]} content={content3} />
            </TabItemsDirective>
          </TabComponent>
        </div>


      </div>

      {/* Fixed Bottom Pane */}
      <div className="fixedBottomPane">
        <FixedBottom
          Machine_Unit_Data={Machine_Unit_Data}
          handlePrint={handlePrint}
          handleSave={handleSave}
          ShowSaveButton={ShowSaveButton}
          HandleUpdateData={HandleUpdateData}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

export default PPS;
