import React from "react";

const BarrelSettingsGrid = ({ PPS_Data }) => {

  const CreateInputData = (event) => {

    const { name, value, dataset } = event.target;

    let data = {

      paramID: dataset.id,
      paramName: name,
      paramUnit: "",
      paramValue: value,

    }

    PPS_Data.Inputs = [
      {
        ...PPS_Data.Inputs[0],
        [dataset.section]: [
          {

            ...PPS_Data.Inputs[0][dataset.section][0],
            [name]: data

          }
        ]
      }
    ]

  }

  return (
    <div id="PPSGrid" className="BarrelSettingsGrid InputsGrid">
      <table>
        <thead>
          <tr>
            <th colSpan="11">
              {" "}
              Barrel Settings Temperature (image not to scale){" "}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Nozzle</td>
            <td> H1 </td>
            <td> H2 </td>
            <td> H3 </td>
            <td> H4 </td>
            <td> H5 </td>
            <td> H6 </td>
            <td> H7 </td>
            <td> H8 </td>
            <td> H9 </td>
            <td> H10 </td>
          </tr>
          <tr>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"11"} data-section={"BarrelTempSetting"} name="fldNozzle" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldNozzle?.paramValue}
              />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"12"} data-section={"BarrelTempSetting"} name="fldH1" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldH1?.paramValue}
              />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"13"} data-section={"BarrelTempSetting"} name="fldH2" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldH2?.paramValue}
              />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"14"} data-section={"BarrelTempSetting"} name="fldH3" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldH3?.paramValue}
              />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"15"} data-section={"BarrelTempSetting"} name="fldH4" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldH4?.paramValue}
              />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"16"} data-section={"BarrelTempSetting"} name="fldH5" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldH5?.paramValue}
              />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"17"} data-section={"BarrelTempSetting"} name="fldH6" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldH6?.paramValue}
              />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"18"} data-section={"BarrelTempSetting"} name="fldH7" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldH7?.paramValue}
              />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"19"} data-section={"BarrelTempSetting"} name="fldH8" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldH8?.paramValue}
              />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"20"} data-section={"BarrelTempSetting"} name="fldH9" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldH9?.paramValue}
              />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"21"} data-section={"BarrelTempSetting"} name="fldH10" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].BarrelTempSetting[0].fldH10?.paramValue}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BarrelSettingsGrid;
