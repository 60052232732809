import React, { useState, useEffect } from "react";
import Header2 from "../../common/header-component/header2";
import { useHistory, useParams } from "react-router-dom";

// import { checkBoxChange } from "@syncfusion/ej2-react-grids";
// CSS
import "../../App.css";
import { Table } from "reactstrap";
import DataService from "../../../services/ApiService";

import { connect } from "react-redux";
import LeftFormsection from "./LeftFormsection";
import RightFormSection from "./RightFormSection";
import BreadCrumb from "../CommonSections/BreadCrumb";

const MachineView = ({ user }) => {
  const history = useHistory();

  var { RowId } = useParams();

  const [SelectedRowId, setSelectedRowId] = useState(null);

  let BaseUnits = JSON.parse(sessionStorage.getItem("StoredUnits")) || [];

  const [column, setColumn] = useState([]);

  const [StoredUnits, setStoredUnits] = useState([]);

  const [SelectedMachinesUnitData, setSelectedMachinesUnitData] = useState({
    Make: {
      value: "",
      unit_id: "",
    },
    Tonnage: {
      value: "",
      unit_id: "",
    },
    Machine_Number: {
      value: "",
      unit_id: null,
    },
    Max_Mold_Width: {
      value: "",
      unit_id: "",
    },
    Screw_Diameter: {
      value: "",
      unit_id: "",
    },
    Selected_Materials: {
      Materials_Ids: [],
      Materials_Names: [],
      Materials_Indexes: [],
    },
    Max_Injection_Speed: {
      value: "",
      unit_id: "",
    },
    Max_Machine_Pressure: {
      value: "",
      unit_id: "",
    },
    Max_Melt_Temperature: {
      value: "",
      unit_id: "",
    },
    Max_Plastic_Pressure: {
      value: "",
      unit_id: "",
    },
    Number_of_Core_Pulls: {
      value: "",
      unit_id: "",
    },
    Intensification_Ratio: {
      value: "",
      unit_id: null,
    },
    "Max_shot_Capacity(Wt)": {
      value: "",
      unit_id: "",
    },
    Max_Mold_Open_Daylight: {
      value: "",
      unit_id: "",
    },
    "Tiebar_Clearance-Width": {
      value: "",
      unit_id: "",
    },
    Max_Mold_Vertical_Height: {
      value: "",
      unit_id: "",
    },
    Max_Screw_Rotation_Speed: {
      value: "",
      unit_id: "",
    },
    "Type(Platen_Orientation)": {
      value: "Horizontal",
      unit_id: null,
    },
    Max_Screw_Rotation_Linear_Speed: {
      value: "",
      unit_id: "",
    },
    Max_allowable_Mold_Stack_Height: {
      value: "",
      unit_id: "",
    },
    Min_allowable_Mold_Stack_Height: {
      value: "",
      unit_id: "",
    },
  });

  const [machineData, setMachineData] = useState({
    Machine_Number: "",
    Make: "",
    "Type(Platen_Orientation)": "",
    Tonnage: "",
    Screw_Diameter: "",
    Max_Screw_Rotation_Speed: "",
    Max_Screw_Rotation_Liner_Speed: "",
    Max_Machine_Pressure: "",
    Max_Injection_Speed: "",
    Intensification_Ratio: "",
    Max_Plastic_Pressure: "",
    "Max_shot_Capacity(Wt)": "",
    Max_Melt_Temperature: "",
    Min_allowable_Mold_Stack_Height: "",
    Max_allowable_Mold_Stack_Height: "",
    Max_Mold_Open_Daylight: "",
    "Tiebar_Clearance-Width": "",
    Max_Mold_Vertical_Height: "",
    Max_Mold_Width: "",
    Number_of_Core_Pulls: "",
    TonnageUnit: "",
    ScrewDiameterUnit: "",
    MaxMachinePressureUnit: "",
    MaxInjectionSpeedUnit: "",
    MaxShotCapacityUnit: "",
    MinMoldStackHeightUnit: "",
  });

  let [SelectedMaterials, setSelectedMaterials] = useState({
    Materials_Indexes: [],
    Materials_Names: [],
    Materials_Ids: [],
  });

  const [BaseUnitsArray, setBaseUnitsArray] = useState([]);

  const [UnitSettings, setUnitSettings] = useState({
    TemperatureUnit: {},
    DistanceUnit: {},
    ScrewDistanceUnit: {},
    PressureUnit: {},
    SpeedUnit: {},
    LinearSpeedUnit: {},
    WeightUnit: {},
    TonnageUnit: {},
  });

  const GoToEdit = () => {
    history.push({
      pathname: `/database/Options/${RowId}/MachineEdit`,
    });
  };

  const GetData = (id) => {
    DataService.GetMachinesData(id).then((res) => {
      if (res.data.length > 0) {
        if (SelectedRowId) {
          const MachineData = res.data.filter(
            (item) => item.Machine_Id === parseInt(SelectedRowId)
          );

          setSelectedMachinesUnitData(MachineData[0].Units);

          setMachineData(
            MachineData[0].Machine_Data[0] || MachineData[0].Machine_Data
          );

          setSelectedMaterials(MachineData[0].Units.Selected_Materials);

          // console.log(res.data[SelectedRowId].Machine_Data);
        }
      } else {
      }
    });

    DataService.GetFieldsData(id).then((res) => {
      if (res.data.length > 0) {
        if (res.data[0].Machine_Custom_Fields) {
          // console.log(res.data[0].Machine_Custom_Fields);

          setColumn(res.data[0].Machine_Custom_Fields);
        }
      }
    });
  };

  useEffect(() => {
    if (user) {
      GetData(user.id);
    }
  }, [user, SelectedRowId]);

  useEffect(() => {
    let allUnits = [];

    // Iterate through each category in the data
    for (const category in BaseUnits.Unit_Data[0]) {
      // Add each unit object to the allUnits array
      allUnits = allUnits.concat(BaseUnits.Unit_Data[0][category]);
    }

    setStoredUnits(allUnits);

    setBaseUnitsArray(BaseUnits.Unit_Data[0]);

    setSelectedRowId(atob(RowId));
  }, [RowId]);

  return (
    <>
      <Header2 Title="Database" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <BreadCrumb DB_Name={"Machine"} Current_Page={"View"} TabIdx={2} />
        </div>

        <div className="container-fluid">
          <div className="card p-3  ml-2">
            <div className="d-flex col-md-12">
              <div className="pt-2 pb-2">
                <button
                  className="btn btn-primary btn-air-primary mr-2"
                  onClick={GoToEdit}
                >
                  Edit
                </button>
              </div>
            </div>

            <div className="d-flex col-md-12">
              <div className="col-md-6">
                <div className="form-inline">
                  <LeftFormsection
                    SelectedMachinesUnitData={SelectedMachinesUnitData}
                    UnitSettings={UnitSettings}
                    BaseUnitsArray={BaseUnitsArray}
                    StoredUnits={StoredUnits}
                    Page={"View"}
                  />
                </div>
              </div>

              <div className="ml-1  col-md-6">
                <div className="form-inline">
                  <RightFormSection
                    SelectedMachinesUnitData={SelectedMachinesUnitData}
                    UnitSettings={UnitSettings}
                    BaseUnitsArray={BaseUnitsArray}
                    StoredUnits={StoredUnits}
                    Page={"View"}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex col-md-12">
              <div className="ml-5 mt-3 col-md-4">
                <div
                  className="p-2 mt-3  b-l-6 b-r-6"
                  style={{ background: "#ebebeb" }}
                >
                  Material(s) :
                  <input
                    className="form-control b-b-primary"
                    value={SelectedMaterials?.Materials_Names || []}
                    readOnly
                  />
                </div>
              </div>

              <div className="ml-3 col-md-6" id="cusFieldT">
                <div className="row mt-3">
                  <label> Custom Fields </label>
                </div>

                <div style={{ border: "1px solid black", width: "255px" }}>
                  <Table
                    id="Custom_Fields_Table"
                    className="table-responsive"
                    width={300}
                    height={200}
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    <thead>
                      <tr>
                        <th align="center" style={{ width: "160px" }}>
                          Name
                        </th>

                        <th align="center" style={{ width: "160px" }}>
                          Value
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {column.map((HeaderName, HeaderKey) => (
                        <tr key={HeaderKey}>
                          <td align="left" style={{ background: "#fff" }}>
                            <input
                              type="text"
                              className="form-control"
                              style={{ width: "125px" }}
                              defaultValue={HeaderName.headerText}
                              readOnly
                            />{" "}
                          </td>

                          <td align="left" style={{ background: "#fff" }}>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={machineData[HeaderName.field]}
                              name={HeaderName.field}
                              style={{ width: "125px" }}
                              readOnly
                            />{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MachineView);
