import React, { useState } from "react";
import {
  HtmlEditor,
  Toolbar,
  RichTextEditorComponent,
  Inject,
} from "@syncfusion/ej2-react-richtexteditor";

const DefectSolution = () => {
  const toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "StrikeThrough",
      "FontName",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "LowerCase",
      "UpperCase",
      "|",
      "Formats",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Outdent",
      "Indent",
      "|",
      "CreateLink",
      "Image",
      "|",
      "ClearFormat",
      "Print",
      "SourceCode",
      "FullScreen",
      "|",
      "Undo",
      "Redo",
    ],
  };

  const [notes, setNotes] = useState("");

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handlePrint = () => {
    window.print(); // Example implementation, replace with your actual print logic
  };

  const handleSave = () => {
    alert("Saved!"); // Example implementation, replace with your actual save logic
  };

  const handleClose = () => {
    alert("Closed!"); // Example implementation, replace with your actual close logic
  };

  return (
    <div>
      <div className="TabCardTitle">
        <span> Notes </span>
      </div>
      <div>
        <RichTextEditorComponent
          value={""}
          toolbarSettings={toolbarSettings}
          height={"60vh"}
          width={"85vw"}
        >
          <Inject services={[Toolbar, HtmlEditor]} />
        </RichTextEditorComponent>
      </div>
    </div>
  );
};

export default DefectSolution;
