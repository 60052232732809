import React from "react";
import { GridComponent } from "@syncfusion/ej2-react-grids";

const Temperature = ({
  TemperatureEnglishDecimals,
  setTemperatureEnglishDecimals,
  TemperatureMetricDecimals,
  setTemperatureMetricDecimals,
  StoredUnits,
  DistanceUnits,
  SpeedUnits,
  PressureUnits,
  TemperatureUnits,
  TimeUnits,
  AreaUnits,
  WeightUnits,
  setSelectedUnitsData,
  showAlert,
}) => {
  // Function to store the metric units data
  const StoreTemperatureMetricUnits = () => {
    showAlert.current = true;

    setTemperatureEnglishDecimals(0);

    TemperatureUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Temperature[0].unit_id
        ? StoredUnits.Unit_Data[0].Temperature[0].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Temperature[0].unit_name
        ? StoredUnits.Unit_Data[0].Temperature[0].unit_name
        : null,
      decimals: TemperatureMetricDecimals,
    };

    // console.log(TemperatureUnits.current)

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // Function to store the english units data
  const StoreTemperatureEnglishUnits = () => {
    showAlert.current = true;

    setTemperatureMetricDecimals(0);

    TemperatureUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Temperature[1].unit_id
        ? StoredUnits.Unit_Data[0].Temperature[1].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Temperature[1].unit_name
        ? StoredUnits.Unit_Data[0].Temperature[1].unit_name
        : null,
      decimals: TemperatureEnglishDecimals,
    };

    // console.log(TemperatureUnits.current)

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // HTML input template to bind with the grid for metric part
  function TemperatureMetricRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="TemperatureUnit"
        defaultChecked={
          TemperatureUnits.current.unit_name === `${props.MetricUnit}`
            ? true
            : false
        }
        onClick={StoreTemperatureMetricUnits}
      />
    );
  }

  // HTML input template to bind with the grid for english part
  function TemperatureEnglishRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="TemperatureUnit"
        defaultChecked={
          TemperatureUnits.current.unit_name === `${props.EnglishUnit}`
            ? true
            : false
        }
        onClick={StoreTemperatureEnglishUnits}
      />
    );
  }

  const StoreTemperatureMetricDecimals = (e) => {
    setTemperatureMetricDecimals(e.target.value);

    TemperatureUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Temperature[0].unit_id
        ? StoredUnits.Unit_Data[0].Temperature[0].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Temperature[0].unit_name
        ? StoredUnits.Unit_Data[0].Temperature[0].unit_name
        : null,
      decimals: parseFloat(e.target.value),
    };

    // console.log(TemperatureUnits.current)

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // HTML drop down template to bind with the grid for metric part
  function TemperatureDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        defaultValue={TemperatureMetricDecimals}
        onChange={StoreTemperatureMetricDecimals}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  const StoreTemperatureEnglishDecimals = (e) => {
    setTemperatureEnglishDecimals(e.target.value);

    TemperatureUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Temperature[1].unit_id
        ? StoredUnits.Unit_Data[0].Temperature[1].unit_id
        : null,
      unit_name: StoredUnits.Unit_Data[0].Temperature[1].unit_name
        ? StoredUnits.Unit_Data[0].Temperature[1].unit_name
        : null,
      decimals: parseFloat(e.target.value),
    };

    // console.log(TemperatureUnits.current)

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // HTML drop down template to bind with the grid for english part
  function TemperatureEnglishDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        defaultValue={TemperatureEnglishDecimals}
        onChange={StoreTemperatureEnglishDecimals}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  let TemperatureData = [
    {
      MetricUnit:
        `${StoredUnits.Unit_Data[0].Temperature[0].unit_name}` || null,
      Metric: 20,
      MetricDecimal: 0,
      EnglishUnit:
        `${StoredUnits.Unit_Data[0].Temperature[1].unit_name}` || null,
      English: 20,
      EnglishDecimal: 0,
    },
  ];

  const TemperatureColumns = [
    {
      field: "MetricUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "Metric",
      template: TemperatureMetricRadioTemplate.bind(this),
      headerText: "Metric",
      width: 100,
    },
    {
      field: "MetricDecimal",
      headerText: "Decimals",
      template: TemperatureDropDownTemplate.bind(this),
      width: 100,
    },
    {
      field: "EnglishUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "English",
      headerText: "English",
      width: 100,
      template: TemperatureEnglishRadioTemplate.bind(this),
    },
    {
      field: "EnglishDecimal",
      headerText: "Decimals",
      width: 100,
      template: TemperatureEnglishDropDownTemplate.bind(this),
    },
  ];

  return (
    <div>
      <GridComponent
        id="Grid"
        dataSource={TemperatureData}
        columns={TemperatureColumns}
      ></GridComponent>
    </div>
  );
};

export default Temperature;
