import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table'
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form';
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import ProcessEnglishunit from './processTransfer/ProcessEnglishunit';

const ProcessTransfer = ({ calculateResult, result, processInput }) => {

  const handleChange = (event) => {

    processInput = { ...processInput, [event.target.name]: event.target.value }

    let flag = true;

    for (let key in processInput) {
      if (processInput[key] <= 0)
        flag = false;
    }

    if (flag) {
      calculateResult(processInput);
    }

  };


  let headertext = [
    { text: "English Units" },
    // { text: "Matric Units 1(bar)" },
    // { text: "Matric Units 2(Mpa)" },
  ];

  function content0() {
    return (

      <div className="ml-1">
        <ProcessEnglishunit handleChange={handleChange} processInput={processInput} result={result} />
      </div>

    );
  }

  function content1() {
    return (
      <div className="card pt-3 pr-1 pl-1 pb-3 ml-2">
        <h2>Matric Units 1(bar)</h2>
      </div>
    );
  }

  function content2() {
    return (
      <div className="card pt-3 pr-1 pl-1 pb-3 ml-2">

      </div>
    );
  }




  return (
    <div>


      <TabComponent heightAdjustMode="Auto" id="defaultTab" style={{ padding: "0 16", }}>
        <TabItemsDirective>
          <TabItemDirective header={headertext[0]} content={content0} />
          {/* <TabItemDirective header={headertext[1]} content={content1} /> */}
          {/* <TabItemDirective header={headertext[2]} content={content2} /> */}


        </TabItemsDirective>
      </TabComponent>



    </div>

    // <Tabs
    //     defaultActiveKey="English Units"
    //     className="mb-3"
    // >
    //     <Tab eventKey="English Units" title="English Units">
    //         <Form>
    //             <Table className="thistable" striped bordered hover variant="light" size="sm">
    //                 <thead>
    //                     <tr>
    //                         <td>Parameters</td>
    //                         <td>Value of Current Machine</td>
    //                         <td>Values for Machine under Consideration (English Units)</td>
    //                         <td>Values for Machine under Consideration (Metric Units 1)</td>
    //                         <td>Values for Machine under Consideration (Metric Units 2)</td>
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     <tr>
    //                         <td>Intensification Ratio (IR)</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="intensificationRatio" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.intensificationRatio} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="intensificationRatioTarget" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.intensificationRatioTarget} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>-----</td>
    //                         <td>-----</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Screw Diameter in mm</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="screwDiameter" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.screwDiameter} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="screwDiameterTarget" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.screwDiameterTarget} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>-----</td>
    //                         <td>-----</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Shot Size in inches</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="shotSize" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.shotSize} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>{result.shotSize}</td>
    //                         <td>{(result.shotSize * 25.4).toFixed(2)} (mm)</td>
    //                         <td>{(result.shotSize * 25.4).toFixed(2)} (mm)</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Transfer position</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="transferPosition" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.transferPosition} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>-----</td>
    //                         <td>-----</td>
    //                         <td>-----</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Shot Size Transfer</td>
    //                         <td>{result.shotSizeTransfer}</td>
    //                         <td></td>
    //                         <td>-----</td>
    //                         <td>-----</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Injection Pressure 1 (psi)</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="injectionPressure1" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.injectionPressure1} onChange={handleChange} size='sm' />
    //                             </Form.Group>
    //                         </td>
    //                         <td>{result.injectionPressure1}</td>
    //                         <td>{(result.injectionPressure1 * 0.0690).toFixed(2)} (bar)</td>
    //                         <td>{(result.injectionPressure1 * 0.00690).toFixed(2)} (MPa)</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Injection Pressure 2 (psi)</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="injectionPressure2" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.injectionPressure2} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>{result.injectionPressure2}</td>
    //                         <td>{(result.injectionPressure2 * 0.0690).toFixed(2)} (bar)</td>
    //                         <td>{(result.injectionPressure2 * 0.00690).toFixed(2)} (MPa)</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Injection Pressure 3 (psi)</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="injectionPressure3" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.injectionPressure3} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>{result.injectionPressure3}</td>
    //                         <td>{(result.injectionPressure3 * 0.0690).toFixed(2)} (bar)</td>
    //                         <td>{(result.injectionPressure3 * 0.00690).toFixed(2)} (MPa)</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Fill Time (Sec) :</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="fillTime" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.fillTime} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>-----</td>
    //                         <td>-----</td>
    //                         <td>-----</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Calc Inj Speed On Mach 1 :</td>
    //                         <td>{result.caloInjSpeed}</td>
    //                         <td></td>
    //                         <td>-----</td>
    //                         <td>-----</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Holding Pressure 1 (psi)</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="holdingPressure1" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.holdingPressure1} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>{result.holdingPressure1}</td>
    //                         <td>{(result.holdingPressure1 * 0.0690).toFixed(2)} (bar)</td>
    //                         <td>{(result.holdingPressure1 * 0.00690).toFixed(2)} (MPa)</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Holding Pressure 2 (psi)</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="holdingPressure2" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.holdingPressure2} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>{result.holdingPressure2}</td>
    //                         <td>{(result.holdingPressure1 * 0.0690).toFixed(2)} (bar)</td>
    //                         <td>{(result.holdingPressure2 * 0.00690).toFixed(2)} (MPa)</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Holding Pressure 3 (psi)</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="holdingPressure3" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.holdingPressure3} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>{result.holdingPressure3}</td>
    //                         <td>{(result.holdingPressure1 * 0.0690).toFixed(2)} (bar)</td>
    //                         <td>{(result.holdingPressure3 * 0.00690).toFixed(2)} (MPa)</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Back Pressure 1 (psi)</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="backPressure" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.backPressure} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td></td>
    //                         <td></td>
    //                         <td></td>
    //                     </tr>
    //                     <tr>
    //                         <td>Screw rpm</td>
    //                         <td>
    //                             <Form.Group className="thisform" controlId="formBasicnumber">
    //                                 <Form.Control name="screwRPM" type="text" onKeyPress={(event) => {
    //                                     if (!/[0.0-9.0]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }} value={processInput.screwRPM} onChange={handleChange} size='sm' min="0" />
    //                             </Form.Group>
    //                         </td>
    //                         <td>{result.screwRPM}</td>
    //                         <td>{result.screwRPM}</td>
    //                         <td>{result.screwRPM}</td>
    //                     </tr>
    //                 </tbody>
    //             </Table>
    //         </Form>
    //     </Tab>
    //  <Tab eventKey="Metric Units - I" title="Metric Units - I">

    // </Tab>
    //<Tab eventKey="Metric Units - II" title="Metric Units - II">

    // </Tab> 
    // </Tabs>
  )
}

export default ProcessTransfer