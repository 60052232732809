import React, { useState, useRef } from "react";
import {
  SpreadsheetComponent,
  SheetsDirective,
  SheetDirective,
  RowsDirective,
  RowDirective,
  ColumnsDirective,
  ColumnDirective,
  CellsDirective,
  CellDirective,
  getRangeAddress
} from "@syncfusion/ej2-react-spreadsheet";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const HR = ({ PPS_Data }) => {

  const HRSpreadsheetRef = useRef(null);

  const scrollSettings = { isFinite: true };

  const dialogBeforeOpen = (args) => {
    if (args.dialogName === "EditAlertDialog") {
      args.cancel = true;
    }

    // Edit the dialog content using the dialogBeforeOpen event.
    if (args.dialogName === "ValidationErrorDialog") {
      args.cancel = true;
    }
  }

  const [ModalStates, setModalStates] = useState({
    AddZones: {
      visibility: false,
      NoOfZones: 12,
      ColCount: 2,
    },
    MaxZonesAlert: {
      visibility: false,
      message: "Number Of Zones cannot exceed 144"
    },
    EmptyZonesAlert: {
      visibility: false,
      message: "Please enter number of zones"
    },
    ZeroZonesAlert: {
      visibility: false,
      message: "This is not a Hot Runner Mold"
    }
  })

  const ToggleAddZoneState = () => {

    setModalStates((prevState) => ({
      ...prevState,
      AddZones: {
        ...prevState.AddZones,
        visibility: !ModalStates.AddZones.visibility
      },
      EmptyZonesAlert: {
        visibility: false,
        message: "Please enter number of zones"
      }
    }))

  }

  const ToggleMaxZoneState = () => {

    setModalStates((prevState) => ({
      ...prevState,
      MaxZonesAlert: {
        ...prevState.MaxZonesAlert,
        visibility: !ModalStates.MaxZonesAlert.visibility
      }
    }))

  }

  const ToggleEmptyZonesState = () => {

    setModalStates((prevState) => ({
      ...prevState,
      EmptyZonesAlert: {
        ...prevState.EmptyZonesAlert,
        visibility: !ModalStates.EmptyZonesAlert.visibility
      }
    }))

  }

  const ToggleZeroZonesState = () => {

    setModalStates((prevState) => ({
      ...prevState,
      ZeroZonesAlert: {
        ...prevState.ZeroZonesAlert,
        visibility: !ModalStates.ZeroZonesAlert.visibility
      }
    }))

  }

  const GetNoOfZones = (e) => {

    setModalStates((prevState) => ({
      ...prevState,
      AddZones: {
        ...prevState.AddZones,
        NoOfZones: e.target.value
      }
    }))

  }

  const ResetFormatOfSheet = async () => {

    try {

      let SettingCount = 1;

      let ZoneCols = ["A", "C", "E", "G", "I", "K"];

      let SettingCols = ["B", "D", "F", "H", "J", "L"]

      for (let i = 1; i <= 6; i++) {

        for (let j = 1; j < 25; j++) {

          HRSpreadsheetRef.current.updateCell(
            { value: parseInt(SettingCount) > parseInt(ModalStates.AddZones.NoOfZones) ? "" : SettingCount },
            `${ZoneCols[i - 1]}${j + 1}`
          );

          HRSpreadsheetRef.current.cellFormat(
            { display: "inline-masonry" },
            `${ZoneCols[i - 1]}${j + 1}`
          );

          HRSpreadsheetRef.current.cellFormat(
            { display: "inline-masonry" },
            `${SettingCols[i - 1]}${j + 1}`
          )

          SettingCount++;

        }

      }

      if (ModalStates.ZeroZonesAlert.visibility === true) {
        ToggleZeroZonesState()
      }

    }
    catch (error) {

      console.error("Error while applying format:", error);

      throw (error)

    }

  }

  const ApplyNewFormatToSheet = async () => {

    try {

      let SettingCount = 1

      let ZoneCols = ["A", "C", "E", "G", "I", "K"]

      let SettingCols = ["B", "D", "F", "H", "J", "L"]

      for (let i = 1; i <= Math.ceil(ModalStates.AddZones.NoOfZones / 24); i++) {

        HRSpreadsheetRef.current.cellFormat(
          { backgroundColor: "rgba(174,194,221,255)" },
          `${ZoneCols[i]}1`
        )

        HRSpreadsheetRef.current.cellFormat(
          { backgroundColor: "rgba(174,194,221,255)" },
          `${SettingCols[i]}1`
        )

        for (let j = 1; j < 25; j++) {

          HRSpreadsheetRef.current.updateCell(
            { value: parseInt(SettingCount) > parseInt(ModalStates.AddZones.NoOfZones) ? "" : SettingCount },
            `${ZoneCols[i - 1]}${j + 1}`
          )

          HRSpreadsheetRef.current.cellFormat(
            { backgroundColor: "rgba(250, 240, 225, 255)" },
            `${SettingCols[i]}${j + 1}`
          );

          if (parseInt(SettingCount) > parseInt(ModalStates.AddZones.NoOfZones)) {

            HRSpreadsheetRef.current.cellFormat(
              { display: "none" },
              `${ZoneCols[i - 1]}${j + 1}`
            )

            HRSpreadsheetRef.current.cellFormat(
              { display: "none" },
              `${SettingCols[i - 1]}${j + 1}`
            )

          }

          SettingCount++

        }

      }

      HRSpreadsheetRef.current.rowCount = SettingCount > 25 ? 25 : SettingCount

      HRSpreadsheetRef.current.refresh()

    }
    catch (error) {

      console.error("Error while applying format:", error)

      throw (error)

    }

  }

  const UpdateSheet = async () => {

    if (parseInt(ModalStates.AddZones.NoOfZones) !== 0 && parseInt(ModalStates.AddZones.NoOfZones) < 144) {

      try {

        setModalStates((prevState) => ({
          ...prevState,
          AddZones: {
            ...prevState.AddZones,
            ColCount: parseInt(Math.ceil(ModalStates.AddZones.NoOfZones / 24) * 2)
          }
        }))

        await ResetFormatOfSheet()

        await ApplyNewFormatToSheet()

        ToggleAddZoneState()

      }
      catch (error) {

        console.error("Error in UpdateSheet:", error);
        throw error;

      }

    }
    else if (parseInt(ModalStates.AddZones.NoOfZones) === 0) {

      ToggleZeroZonesState()

      ToggleAddZoneState()

    }
    else if (ModalStates.AddZones.NoOfZones === '') {

      ToggleEmptyZonesState()

    }
    else {

      ToggleMaxZoneState()

    }

  }

  const GetAllColData = async () => {
    try {
      let TempArray = [];

      const cells = await HRSpreadsheetRef.current.getData(
        HRSpreadsheetRef.current.getActiveSheet().name +
        "!" +
        getRangeAddress([1, 0, 25, 12])
      );

      cells.forEach((cell, key) => {
        TempArray.push(cell.value ? cell.value : null);
      })

      return TempArray;
    } catch (error) {
      console.error("Error in GetAllColData:", error);
      throw error;
    }
  }

  const ConvertToObject = async (AllColData) => {
    try {
      let TempDataArray = [];
      let NumberOfRow = 0;

      for (
        let i = 0;
        i < 144;
        i++
      ) {
        let TempDataObj = {};

        TempDataObj["ZoneNum"] = AllColData[i + NumberOfRow];
        TempDataObj["SettVal"] = AllColData[i + NumberOfRow + 1];

        NumberOfRow += 12;
        TempDataArray.push(TempDataObj);
      }

      return TempDataArray;
    } catch (error) {
      console.error("Error in ConvertToObject:", error);
      throw error;
    }
  };

  const GatherData = async () => {
    try {

      // Now, Finally getting all the data in the sheet
      const ColData = await GetAllColData();

      // Then converting it to an array of objects
      const ObjData = await ConvertToObject(ColData);

      PPS_Data.HRSett["fldZones"] = ObjData

    }
    catch (error) {
      console.error("Error in GatherData:", error);
      throw error;
    }
  }

  const PopulateSheet = () => {

    let SettingCols = ["B", "D", "F", "H", "J", "L"];
    let ZoneCols = ["A", "C", "E", "G", "I", "K"];

    let SettingCount = 1;

    for (let i = 1; i <= Math.ceil(ModalStates.AddZones.NoOfZones / 24); i++) {

      for (let j = 1; j < 25; j++) {

        HRSpreadsheetRef.current.updateCell(
          { value: SettingCount > ModalStates.AddZones.NoOfZones ? "" : SettingCount },
          `${ZoneCols[i - 1]}${j + 1}`
        );

        SettingCount++;
      }

    }

    // console.log( PPS_Data )

  }

  const onCreated = async () => {

    ApplyNewFormatToSheet()

    try {

      HRSpreadsheetRef.current.cellFormat({ textAlign: "center" }, "A1:L25");

      HRSpreadsheetRef.current.lockCells("A2:L25", false);

      HRSpreadsheetRef.current.protectSheet(null, {
        selectCells: true
      });

      let SettingCols = ["B", "D", "F", "H", "J", "L"];
      let ZoneCols = ["A", "C", "E", "G", "I", "K"];

      let SettingCount = 1;

      for (let i = 1; i <= Math.ceil(ModalStates.AddZones.NoOfZones / 24); i++) {

        HRSpreadsheetRef.current.cellFormat(
          { backgroundColor: "rgba(174,194,221,255)" },
          `${ZoneCols[i - 1]}1`
        );

        HRSpreadsheetRef.current.cellFormat(
          { backgroundColor: "rgba(174,194,221,255)" },
          `${SettingCols[i - 1]}1`
        );

        for (let j = 1; j < 25; j++) {

          HRSpreadsheetRef.current.updateCell(
            { value: SettingCount > ModalStates.AddZones.NoOfZones ? "" : SettingCount },
            `${ZoneCols[i - 1]}${j + 1}`
          );

          HRSpreadsheetRef.current.cellFormat(
            { backgroundColor: "rgba(250, 240, 225, 255)" },
            `${SettingCols[i - 1]}${j + 1}`
          );

          HRSpreadsheetRef.current.lockCells(`${SettingCols[i - 1]}${j + 1}`, false);

          SettingCount++;
        }

      }

    }
    catch (error) {
      console.error("Error on sheet creation:", error);
      throw error;
    }

  }

  function actionBegin(args) {

    if (args.action === 'clipboard' && args.args.eventArgs.requestType === 'paste') {
      //Set the type to 'Values' to paste only the values.
      args.args.eventArgs.type = 'Values';

    }
  }

  return (
    <>

      <Modal isOpen={ModalStates.MaxZonesAlert.visibility} centered={true} toggle={ToggleMaxZoneState}>
        <ModalHeader toggle={ToggleAddZoneState}> Max Number Of Zones </ModalHeader>
        <ModalBody>
          <div className="d-flex">
            <span>
              <strong>
                {ModalStates.MaxZonesAlert.message}
              </strong>
            </span>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="fourth" onClick={ToggleMaxZoneState}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={ModalStates.AddZones.visibility} centered={true} toggle={ToggleAddZoneState}>
        <ModalHeader toggle={ToggleAddZoneState}> Number Of Zones </ModalHeader>
        <ModalBody>

          {ModalStates.EmptyZonesAlert.visibility ? <span style={{ color: "red" }}> * <strong> {ModalStates.EmptyZonesAlert.message} </strong> </span> : <></>}

          <div className="d-flex">

            <span style={{ width: "35%" }}>
              Enter Number Of Zones:{" "}
            </span>

            <input
              style={{ width: "35%" }}
              className="form-control"
              value={ModalStates.AddZones.NoOfZones}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              type="text"
              placeholder="Enter Number Of Zones"
              name="rows"
              onChange={GetNoOfZones}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" type="submit" onClick={UpdateSheet}>
            {" "}
            Add{" "}
          </Button>
          <Button color="fourth" onClick={ToggleAddZoneState}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <div
        id="Hr_Sheet"
        className="spreadsheet"
        style={{ width: "85vw", height: "68vh" }}
      >
        <div className="TabCardTitle">
          <span> Hot Runner Controller Settings </span>
        </div>

        <div>
          <button className="btn btn-secondary btn-air-secondary mt-1 mb-2" onClick={ToggleAddZoneState}>
            Add No. Of Zones
          </button>
        </div>

        <div>
          <SpreadsheetComponent
            style={{ display: ModalStates.ZeroZonesAlert.visibility ? "none" : "block" }}
            ref={HRSpreadsheetRef}
            height={"85%"}
            width={"95%"}
            onBlur={GatherData}
            cellEdit={GatherData}
            cellEdited={GatherData}
            showFormulaBar={false}
            showSheetTabs={false}
            showRibbon={false}
            dialogBeforeOpen={dialogBeforeOpen.bind(this)}
            scrollSettings={scrollSettings}
            created={onCreated}
            allowAutoFill={false}
            enableContextMenu={false}
            allowImage={false}
            actionBegin={actionBegin}
          >
            <SheetsDirective>
              <SheetDirective
                frozenRows={1}
                rowCount={13}
                colCount={ModalStates.AddZones.ColCount}
                showHeaders={false}
              >
                <RowsDirective>
                  <RowDirective>
                    <CellsDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                      <CellDirective value="Zone no."></CellDirective>
                      <CellDirective value="Settings"></CellDirective>
                    </CellsDirective>
                  </RowDirective>
                </RowsDirective>
                <ColumnsDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                  <ColumnDirective width={100}></ColumnDirective>
                </ColumnsDirective>
              </SheetDirective>
            </SheetsDirective>
          </SpreadsheetComponent>

          {ModalStates.ZeroZonesAlert.visibility ?
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <span style={{ color: "red", fontSize: '1.5em' }}> *{ModalStates.ZeroZonesAlert.message} </span>
            </div>
            :
            <></>
          }
        </div>
      </div>
    </>
  );
};

export default HR;
