import React, { useEffect } from "react";
import Header2 from "../common/header-component/header2";
import MainModuleCard from "./MainModuleCard";
import {
  FaDatabase,
  FaPuzzlePiece,
  FaTools,
  FaCalculator,
  FaExchangeAlt,
  FaCogs,
  FaBook
} from "react-icons/fa";
import { GiFactory } from "react-icons/gi";
import "./card.css";

const MainDashboard = () => {

  const NameTitleObj = [
    {
      Path: "/database/Database",
      Title: "Databases",
      Icon: <FaDatabase style={{ color: "#4caf50", fontSize: "2em" }} />,

    },
    {
      Path: "/compatibility/Compatible",
      Title: "Compatibility",
      Icon: <FaPuzzlePiece style={{ color: "#f57c00", fontSize: "2em" }} />,

    },
    {
      Path: "/mold",
      Title: "Mold",
      Icon: <GiFactory style={{ color: "#2196f3", fontSize: "2em" }} />,

    },
    {
      Path: "/sessions/equipmentqualification",
      Title: "Equipment Qualification",
      Icon: <FaTools style={{ color: "#ff5722", fontSize: "2em" }} />,

    },
    {
      Path: "/calculators",
      Title: "Calculators",
      Icon: <FaCalculator style={{ color: "#673ab7", fontSize: "2em" }} />,

    },
    {
      Path: "/conversions",
      Title: "Conversions",
      Icon: <FaExchangeAlt style={{ color: "#00bcd4", fontSize: "2em" }} />,

    },
    {
      Path: "/optionnsettings/OptionSetting",
      Title: "Options",
      Icon: <FaCogs style={{ color: "#e91e63", fontSize: "2em" }} />,

    },
    {
      Path: "/LearningCenter",
      Title: "Learning Center",
      Icon: <FaBook style={{ color: "#e2a223", fontSize: "2em" }} />,

    },
  ];

  useEffect(() => {

    sessionStorage.removeItem("SelectedMoldData")

  }, [])

  return (
    <>
      <Header2 Title="Nautilus Web" />

      <div className="mt-2 container-fluid">

        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex ml-4">
            <div>
              <h3> Dashboard </h3>
            </div>
          </div>
        </div>

        <div id="main-dashboard" className="d-flex flex-wrap">

          {NameTitleObj.map((item, index) => (
            <div className="col-md-3 mb-4" key={index}>
              <MainModuleCard ModuleTitle={item.Title} ModulePath={item.Path} Icon={item.Icon} />
            </div>
          ))}

        </div>

      </div>
    </>
  );
};

export default MainDashboard;
