import React, { useEffect } from "react";
import { getCell } from "@syncfusion/ej2-spreadsheet";

const DropDownMenu = ({
  HeaderArray,
  SelectedColHeader,
  PCSheetRowCount,
  setIsLoading,
  PCSpreadsheet,
  setSelectedColData,
  CalculateBin,
  CreateChartData,
  setBin_Interval,
  setChartData,
  setSelectedColHeader,
  PerformCalcs,
}) => {

  const getIndex = async (e) => {
    if (e.target.value !== 0) {
      return e.target.value;
    }
    throw new Error("Invalid index value");
  };

  const getSheetData = async (idx) => {
    let TempArray = [];
    const activeSheet = PCSpreadsheet.current.getActiveSheet();

    for (let i = 5; i < PCSheetRowCount; i++) {
      const cellValue = getCell(i, idx ? idx : 1, activeSheet).value;
      if (cellValue) {
        TempArray.push(cellValue);
      }
    }

    return TempArray;
  };

  const GetValues = async (TempArray, idx) => {
    const activeSheet = PCSpreadsheet.current.getActiveSheet();

    const char_name = getCell(0, idx ? idx : 1, activeSheet).value || null;
    const upper_limit = getCell(1, idx ? idx : 1, activeSheet).value || null;
    const target = getCell(2, idx ? idx : 1, activeSheet).value || null;
    const lower_limit = getCell(3, idx ? idx : 1, activeSheet).value || null;
    const sub_group_size = getCell(4, idx ? idx : 1, activeSheet).value || null;

    const TempData = {
      values: TempArray,
      char_name,
      sub_group_size,
      upper_limit,
      lower_limit,
      target,
    };

    setSelectedColHeader(char_name);
    return TempData;
  };

  const GetData = async (e) => {
    try {
      setIsLoading(true);

      const idx = await getIndex(e);
      const TempArray = await getSheetData(idx);
      const ColData = await GetValues(TempArray, idx);

      // Storing all the data entered in the selected column.
      setSelectedColData(ColData);

      PerformCalcs(ColData);

      const binInterval = await CalculateBin(ColData.values);
      setBin_Interval(binInterval);

      // Creating chart data for histogram again in array of objects format.
      const HistoData = await CreateChartData(ColData);
      setChartData(HistoData);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error in GetData:", error);
      // Handle any errors if necessary
    }
  };

  useEffect(() => {
    // setIsLoading(false);

    // console.log(SelectedColHeader)

    var selectElement = document.getElementById("HeaderDropDown");

    for (var i = 0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].text === SelectedColHeader) {
        selectElement.selectedIndex = i;

        break;
      }
    }
    
  }, [SelectedColHeader, HeaderArray]);

  return (
    <div className="d-flex">
      <div className="m-2">
        <select
          className="ml-2 form-control"
          style={{ width: "180px" }}
          name="Headers"
          id="HeaderDropDown"
          onChange={GetData}
        >
          <option value={0}> Select Characteristics: </option>

          {HeaderArray.map((Header, key) => (
            <option key={key} value={key + 1}>
              {" "}
              {Header}{" "}
            </option>
          ))}
        </select>
      </div>

      <div className="ml-2 mt-2">
        <span> Characteristics:- {SelectedColHeader || ""} </span>
      </div>
    </div>
  );
};

export default DropDownMenu;
