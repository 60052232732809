import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useHistory } from "react-router-dom";

const MainModuleCard = ({ ModuleTitle, ModulePath, Icon }) => {
  const history = useHistory();

  const NavigateToModule = (ModulePath) => {
    history.push(`${ModulePath}`);
  };

  return (
    <div>
      <Card className="card" onClick={() => NavigateToModule(`${ModulePath}`)}>
        <CardBody>
          <div className="icon-container">{Icon}</div>
        </CardBody>
        <CardHeader style={{ fontWeight: "bold", fontSize: "1.5em" }}>
          {" "}
          {ModuleTitle}{" "}
        </CardHeader>
      </Card>
    </div>
  );
};

export default MainModuleCard;
