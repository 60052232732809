// Implementing the error function (erf)
function erf(x) {
  const a1 = 0.254829592;
  const a2 = -0.284496736;
  const a3 = 1.421413741;
  const a4 = -1.453152027;
  const a5 = 1.061405429;
  const p = 0.3275911;

  let sign = 1;
  if (x < 0) {
    sign = -1;
  }
  x = Math.abs(x);

  let t = 1.0 / (1.0 + p * x);
  let y =
    1.0 - ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);

  return sign * y;
}

//================================================================================
// Calculate average
//================================================================================
export function calculateAverage(values, subGroupSize) {
  let total = 0;
  let counter = 0;

  for (let i = 0; i < values.length; i += subGroupSize) {
    if (i + subGroupSize > values.length) break;

    for (let j = i; j < i + subGroupSize; j++) {
      total += parseFloat(values[j]);
      counter++;
    }
  }

  return total / counter;
}

//================================================================================
// Calculate overall standard deviation
//================================================================================
export function calculateOverallSD(values, subGroupSize) {
  let total = 0;
  let counter = 0;

  for (let i = 0; i < values.length; i += subGroupSize) {
    if (i + subGroupSize > values.length) break;
    for (let j = i; j < i + subGroupSize; j++) {
      total += parseFloat(values[j]);
      counter++;
    }
  }

  let mean = total / counter;
  let meanTotal = 0;

  for (let i = 0; i < values.length; i += subGroupSize) {
    if (i + subGroupSize > values.length) break;
    for (let j = i; j < i + subGroupSize; j++) {
      meanTotal += Math.pow(mean - parseFloat(values[j]), 2);
    }
  }

  return Math.sqrt(meanTotal / (counter - 1));
}

//================================================================================
// Calculate potential standard deviation
//================================================================================
export function calculatePotentialSD(values, subGroupSize) {
  let range = calculateRange(values, subGroupSize);
  let average = calculateAverage(range, 1);
  const D2 = [
    1.128, 1.128, 1.693, 2.059, 2.326, 2.534, 2.704, 2.847, 2.97, 3.078, 3.173,
    3.258, 3.336, 3.407, 3.472,
  ];
  return average / D2[subGroupSize - 1];
}

//================================================================================
// Calculate range
//================================================================================
export function calculateRange(values, subGroupSize) {
  let rangeList = [];

  if (subGroupSize === 1) {
    for (let i = 1; i < values.length; i++) {
      rangeList.push(
        Math.abs(parseFloat(values[i]) - parseFloat(values[i - 1]))
      );
    }
  } else {
    for (let i = 0; i < values.length; i += subGroupSize) {
      if (i + subGroupSize > values.length) break;

      let maxVal = parseFloat(values[i]);
      let minVal = parseFloat(values[i]);

      for (let j = i; j < i + subGroupSize; j++) {
        if (maxVal < parseFloat(values[j])) maxVal = parseFloat(values[j]);
        if (minVal > parseFloat(values[j])) minVal = parseFloat(values[j]);
      }

      rangeList.push(maxVal - minVal);
    }
  }

  return rangeList;
}

//================================================================================
// Calculate Cp
//================================================================================
export function calculateCp(upperLimit, lowerLimit, values, subGroupSize) {
  if (
    upperLimit === null ||
    lowerLimit === null ||
    isNaN(upperLimit) ||
    isNaN(lowerLimit)
  ) {
    return NaN;
  }

  const range = calculateRange(values, subGroupSize);
  const averageRange = calculateAverage(range, 1);
  const D2 = [
    1.128, 1.128, 1.693, 2.059, 2.326, 2.534, 2.704, 2.847, 2.97, 3.078, 3.173,
    3.258, 3.336, 3.407, 3.472,
  ];
  const potentialSD = averageRange / D2[subGroupSize - 1];

  return (upperLimit - lowerLimit) / (6 * potentialSD);
}

//================================================================================
// Calculate Cpk
//================================================================================
export function calculateCpk(
  values,
  subGroupSize,
  upperLimit = null,
  lowerLimit = null
) {
  const average = calculateAverage(values, subGroupSize);
  const potentialSD = calculatePotentialSD(values, subGroupSize);

  const CpUpper = upperLimit ? (upperLimit - average) / (3 * potentialSD) : NaN;
  const CpLower = lowerLimit ? (average - lowerLimit) / (3 * potentialSD) : NaN;

  return Math.min(CpUpper, CpLower);
}

//================================================================================
// Calculate Pp
//================================================================================
export function calculatePp(upperLimit, lowerLimit, values, subGroupSize) {
  if (
    upperLimit === null ||
    lowerLimit === null ||
    isNaN(upperLimit) ||
    isNaN(lowerLimit)
  ) {
    return NaN;
  }

  const overallSD = calculateOverallSD(values, subGroupSize);
  return (upperLimit - lowerLimit) / (6 * overallSD);
}

//================================================================================
// Calculate Ppk
//================================================================================
export function calculatePpk(upperLimit, lowerLimit, values, subGroupSize) {
  const average = calculateAverage(values, subGroupSize);
  const overallSD = calculateOverallSD(values, subGroupSize);

  const PpUpper = upperLimit ? (upperLimit - average) / (3 * overallSD) : NaN;
  const PpLower = lowerLimit ? (average - lowerLimit) / (3 * overallSD) : NaN;

  return Math.min(PpUpper, PpLower);
}

//================================================================================
// Calculate PPM
//================================================================================
export function calculatePPM(values, subGroupSize, usl, lsl) {
  function probNorm(z) {
    return 0.5 * (1 + erf(z / Math.sqrt(2)));
  }

  const overallSD = calculateOverallSD(values, subGroupSize);
  const average = calculateAverage(values, subGroupSize);

  const zUsl = usl ? (usl - average) / overallSD : null;
  const zLsl = lsl ? (lsl - average) / overallSD : null;

  const lowerPPM = zLsl !== null ? probNorm(zLsl) : null;
  const upperPPM = zUsl !== null ? 1 - probNorm(zUsl) : null;

  const totalPPM = (lowerPPM || 0) + (upperPPM || 0);

  return {
    lowerPPM: lowerPPM ? (lowerPPM * 1000000).toFixed(3) : "NaN",
    upperPPM: upperPPM ? (upperPPM * 1000000).toFixed(3) : "NaN",
    totalPPM: (totalPPM * 1000000).toFixed(3),
  };
}

//================================================================================
// Calculate Data Range
//================================================================================
export function calculateDataRange(values) {
  const max = Math.max(...values);
  const min = Math.min(...values);
  return max - min;
}

//================================================================================
// Calculate Mean
//================================================================================
export function calculateMean(values, subGroupSize) {
  return calculateAverage(values, subGroupSize);
}
