import React from "react";

const PositionGrid = ({ PPS_Data, Machine_Unit_Data }) => {

  const CreateInputData = (event) => {

    const { name, value, dataset } = event.target;

    let data = {

      paramID: dataset.id,
      paramName: name,
      paramUnit: Machine_Unit_Data.distance.unit_id || "",
      paramValue: value,

    }

    if (dataset.section === 'fldObtainedCushion') {

      PPS_Data.Inputs = [
        {
          ...PPS_Data.Inputs[0],
          [dataset.section]: data
        }
      ]

      // console.log(data)

    }
    else if (dataset.section === 'fldShotSize') {

      PPS_Data.Inputs = [
        {
          ...PPS_Data.Inputs[0],
          [dataset.section]: data
        }
      ]

      // console.log(data)

    }
    else if (dataset.section === 'TransferPosition') {

      PPS_Data.Inputs = [
        {
          ...PPS_Data.Inputs[0],
          [dataset.section]: [
            {

              ...PPS_Data.Inputs[0][dataset.section][0],
              [name]: data

            }
          ]
        }
      ]

      // console.log(data)

    }

  }

  return (
    <div id="PPSGrid" className="PositionGrid InputsGrid">
      <table>
        <tbody>
          <tr>
            <td> Cushion* </td>
            <td rowSpan={2}> </td>
            <td> Transfer* </td>
            <td rowSpan={2}> </td>
            <td> Posn4 </td>
            <td rowSpan={2}> </td>
            <td> Posn3 </td>
            <td rowSpan={2}> </td>
            <td> Posn2 </td>
            <td rowSpan={2}> </td>
            <td> Posn1 </td>
            <td rowSpan={2}> </td>
            <td> Shot Size* </td>
          </tr>
          <tr>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"24"} data-section={"fldObtainedCushion"} name="fldObtainedCushion" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].fldObtainedCushion?.paramValue} />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"25"} data-section={"TransferPosition"} name="fldTransferPosition1" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition1?.paramValue} />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"26"} data-section={"TransferPosition"} name="fldTransferPosition5" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition5?.paramValue} />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"27"} data-section={"TransferPosition"} name="fldTransferPosition4" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition4?.paramValue} />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"28"} data-section={"TransferPosition"} name="fldTransferPosition3" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition3?.paramValue} />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"29"} data-section={"TransferPosition"} name="fldTransferPosition2" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].TransferPosition[0].fldTransferPosition2?.paramValue} />
            </td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"30"} data-section={"fldShotSize"} name="fldShotSize" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].fldShotSize?.paramValue} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PositionGrid;
