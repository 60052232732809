import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGOUT_PREV_SESSION,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  LOGIN_RESET_SUCCESS,
  LOGIN_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNIN_SUCCESS,
  SIGNIN_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  GET_STORED_UNITS,
  NO_STORED_UNITS,
  USER_SELECTED_UNITS,
  NO_SELECTED_UNITS,
  GET_MATERIAL_DATA,
  NO_MATERIAL_DATA,
  GET_MACHINE_DATA,
  NO_MACHINE_DATA,
  GET_MOLD_DATA,
  NO_MOLD_DATA,
  GET_FIELDS_DATA,
  NO_FIELDS_DATA,
  GET_PPS_DATA,
  NO_PPS_DATA,
} from "./types";

export const load_user = () => async (dispatch) => {
  if (sessionStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/profile/`,
        config
      );

      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  } else {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

export const checkAuthenticated = () => async (dispatch) => {
  let token = sessionStorage.getItem("access");

  if (token) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const body = JSON.stringify({ token: sessionStorage.getItem("access") });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/token/verify/`,
        body,
        config
      );

      // console.log(res, Exp_Time);

      if (res.data.code !== "token_not_valid") {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });

        return false;
      }
      
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });

      return false;
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });

    return false;
  }
};

export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/login/`,
      body,
      config
    );

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    return true;
  } catch (err) {
    // Capture the error message
    const errorMsg =
      err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : "Invalid credentials provided.";

    // Dispatch failure action without returning the message here
    dispatch({
      type: LOGIN_FAIL,
    });

    // Return the error message
    return errorMsg; // Return the error message instead of false
  }
};

export const signup =
  (name, email, password, re_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ name, email, password, re_password });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/register/`,
        body,
        config
      );

      dispatch({
        type: SIGNIN_SUCCESS,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      dispatch({
        type: SIGNIN_FAIL,
      });
    }
  };

export const verify = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/user/activate/${uid}/${token}/`,
      config
    );

    dispatch({
      type: ACTIVATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAIL,
    });
  }
};

export const resetPassword = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/send-reset-password-email/`,
      body,
      config
    );

    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });

    return res.data.msg;

  } catch (err) {
    const errorMsg =
      err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : "You are not a Registered User";

    dispatch({
      type: PASSWORD_RESET_FAIL,
    });

    return errorMsg;

  }
};

export const resetLogIn = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/send-reset-login-status-email/`,
      body,
      config
    );

    dispatch({
      type: LOGIN_RESET_SUCCESS,
    });

    return res.data.message;
  } catch (err) {
    // Capture the error message
    const errorMsg =
      err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : "You are not a Registered User";

    // console.log(errorMsg)

    dispatch({
      type: LOGIN_RESET_FAIL,
    });

    return errorMsg;
  }
};

export const resetPasswordConfirm =
  (uid, token, password, re_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ password, re_password });

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/user/reset-password/${uid}/${token}/`,
        body,
        config
      );

      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_FAIL,
      });
    }
  };

export const resetLogInConfirm = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    let res = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/reset-user-login-status/${uid}/${token}/`,
      config
    );

    dispatch({
      type: LOGIN_RESET_SUCCESS,
    });

    return res.data.message;
  } catch (err) {
    // Capture the error message
    const errorMsg =
      err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : "You are not a Registered User";

    dispatch({
      type: LOGIN_RESET_FAIL,
    });

    return errorMsg;
  }
};

export const logout = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const body = JSON.stringify({ email });

  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/logout/`,
    body,
    config
  );

  dispatch({
    type: LOGOUT,
  });
};

export const logoutPrevSession = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const body = JSON.stringify({ email });

  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/user/logout/`,
    body,
    config
  );

  dispatch({
    type: LOGOUT_PREV_SESSION,
  });
};

//*********************************************************** */ Database Actions
export const get_units = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/mold_session/stored_units/`,
      config
    );

    // console.log(res);

    dispatch({
      type: GET_STORED_UNITS,
      payload:
        res.data.length === 0
          ?
          {
            id: 1,
            Unit_Data: [
              {
                Area: [
                  {
                    unit_id: 1,
                    decimals: 0.12,
                    unit_name: "sq cm",
                  },
                  {
                    unit_id: 2,
                    decimals: 0.12,
                    unit_name: "sq in",
                  },
                ],
                Time: [
                  {
                    unit_id: 3,
                    decimals: 0,
                    unit_name: "sec",
                  },
                  {
                    unit_id: 4,
                    decimals: 0.1,
                    unit_name: "min",
                  },
                  {
                    unit_id: 5,
                    decimals: 0.12,
                    unit_name: "hrs",
                  },
                ],
                Speed: [
                  {
                    unit_id: 6,
                    decimals: 0.1,
                    unit_name: "mm/sec",
                  },
                  {
                    unit_id: 7,
                    decimals: 0.12,
                    unit_name: "inches/sec",
                  },
                  {
                    unit_id: 21,
                    decimals: 0.12,
                    unit_name: "rpm",
                  },
                ],
                Volume: [
                  {
                    unit_id: 23,
                    unit_name: "cm^3",
                  },
                ],
                Weight: [
                  {
                    unit_id: 8,
                    decimals: 0.12,
                    unit_name: "Gms",
                  },
                  {
                    unit_id: 9,
                    decimals: 0.12,
                    unit_name: "oz",
                  },
                ],
                Tonnage: [
                  {
                    unit_id: 18,
                    unit_name: "US tons",
                  },
                  {
                    unit_id: 19,
                    unit_name: "metric ton",
                  },
                  {
                    unit_id: 20,
                    unit_name: "kN",
                  },
                ],
                Distance: [
                  {
                    unit_id: 10,
                    decimals: 0.12,
                    unit_name: "mm",
                  },
                  {
                    unit_id: 11,
                    decimals: 0.12,
                    unit_name: "in",
                  },
                  {
                    unit_id: 22,
                    decimals: 0.12,
                    unit_name: "cm",
                  },
                ],
                Pressure: [
                  {
                    unit_id: 12,
                    decimals: 0.12,
                    unit_name: "MPa",
                  },
                  {
                    unit_id: 13,
                    decimals: 0,
                    unit_name: "psi",
                  },
                  {
                    unit_id: 14,
                    decimals: 0,
                    unit_name: "bar",
                  },
                  {
                    unit_id: 15,
                    decimals: 0,
                    unit_name: "kpsi",
                  },
                ],
                Temperature: [
                  {
                    unit_id: 16,
                    decimals: 0,
                    unit_name: "deg C",
                  },
                  {
                    unit_id: 17,
                    decimals: 0,
                    unit_name: "deg F",
                  },
                ],
              },
              [
                {
                  unit_id: 1,
                  decimals: 0.12,
                  unit_name: "sq cm",
                },
                {
                  unit_id: 2,
                  decimals: 0.12,
                  unit_name: "sq in",
                },
                {
                  unit_id: 3,
                  decimals: 0,
                  unit_name: "sec",
                },
                {
                  unit_id: 4,
                  decimals: 0.1,
                  unit_name: "min",
                },
                {
                  unit_id: 5,
                  decimals: 0.12,
                  unit_name: "Hrs",
                },
                {
                  unit_id: 6,
                  decimals: 0.1,
                  unit_name: "mm/sec",
                },
                {
                  unit_id: 7,
                  decimals: 0.12,
                  unit_name: "inches/sec",
                },
                {
                  unit_id: 8,
                  decimals: 0.12,
                  unit_name: "Gms",
                },
                {
                  unit_id: 9,
                  decimals: 0.12,
                  unit_name: "oz",
                },
                {
                  unit_id: 10,
                  decimals: 0.12,
                  unit_name: "mm",
                },
                {
                  unit_id: 11,
                  decimals: 0.12,
                  unit_name: "in",
                },
                {
                  unit_id: 12,
                  decimals: 0.12,
                  unit_name: "MPa",
                },
                {
                  unit_id: 13,
                  decimals: 0,
                  unit_name: "psi",
                },
                {
                  unit_id: 14,
                  decimals: 0,
                  unit_name: "bar",
                },
                {
                  unit_id: 15,
                  decimals: 0,
                  unit_name: "kpsi",
                },
                {
                  unit_id: 16,
                  decimals: 0,
                  unit_name: "deg C",
                },
                {
                  unit_id: 17,
                  decimals: 0,
                  unit_name: "deg F",
                },
                {
                  unit_id: 18,
                  unit_name: "US tons",
                },
                {
                  unit_id: 19,
                  unit_name: "metric ton",
                },
                {
                  unit_id: 20,
                  unit_name: "kN",
                },
                {
                  unit_id: 21,
                  decimals: 0.12,
                  unit_name: "rpm",
                },
                {
                  unit_id: 22,
                  decimals: 0.12,
                  unit_name: "cm",
                },
                {
                  unit_id: 23,
                  unit_name: "cm^3",
                },
              ],
            ],
          }
          : res.data[0],
    });

    // console.log(res.data)
  } catch (err) {
    dispatch({
      type: NO_STORED_UNITS,
    });
  }
};

export const get_user_units = (uid) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/mold_session/units_by_id/${uid}`,
      config
    );

    dispatch({
      type: USER_SELECTED_UNITS,
      payload: res.data.message
        ? {
          Unit_Data: [
            {
              Area: {
                unit_id: 1,
                decimals: 0.12,
                unit_name: "sq cm",
              },
              Time: {
                unit_id: 3,
                decimals: 0,
                unit_name: "sec",
              },
              Speed: {
                unit_id: 6,
                decimals: 0.1,
                unit_name: "mm/sec",
              },
              Weight: {
                unit_id: 8,
                decimals: 0.12,
                unit_name: "Gms",
              },
              Distance: {
                unit_id: 10,
                decimals: 0.12,
                unit_name: "mm",
              },
              Pressure: {
                unit_id: 12,
                decimals: 0.12,
                unit_name: "MPa",
              },
              Temperature: {
                unit_id: 16,
                decimals: 0,
                unit_name: "deg C",
              },
            },
          ],
        }
        : res.data,
    });
  } catch (err) {
    dispatch({
      type: NO_SELECTED_UNITS,
    });
  }
};

export const get_materials = (uid) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/databases/all_materials/${uid}`,
      config
    );

    dispatch({
      type: GET_MATERIAL_DATA,
      payload: res.data.map((material) => ({
        id: material.Material_Id,
        Material_Id: material.Material_Data.Material_Id,
        Material_Data: material.Material_Data,
      })),
    });
  } catch (err) {
    dispatch({
      type: NO_MATERIAL_DATA,
    });
  }
};

export const get_machines = (uid) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/databases/all_machine/${uid}`,
      config
    );

    dispatch({
      type: GET_MACHINE_DATA,
      payload: res.data.flatMap((machine) => ({
        id: machine.Machine_Id,
        Machine_Id: machine.Machine_Data.Machine_Number,
        Machine_Data: machine.Machine_Data,
        Machine_Units_Data: machine.Units,
      })),
    });
  } catch (err) {
    dispatch({
      type: NO_MACHINE_DATA,
    });
  }
};

export const get_molds = (uid) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/mold_session/mold/${uid}`,
      config
    );

    dispatch({
      type: GET_MOLD_DATA,
      payload: res.data.message ? [] : res.data,
    });
  } catch (err) {
    dispatch({
      type: NO_MOLD_DATA,
    });
  }
};

export const get_custom_fields = (uid) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/databases/get_fields_data/${uid}`,
      config
    );

    dispatch({
      type: GET_FIELDS_DATA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: NO_FIELDS_DATA,
    });
  }
};

export const get_pps_data = (mold_id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/PPS/PPSData_by_moldid/${mold_id}`,
      config
    );

    // console.log( res.data )

    dispatch({
      type: GET_PPS_DATA,
      payload: res.data.message ? [] : res.data,
    });
  } catch (err) {
    dispatch({
      type: NO_PPS_DATA,
      payload: [],
    });
  }
};
