import React, { useState, useEffect } from "react";
import Header2 from "../../common/header-component/header2";
import { useParams, useHistory } from "react-router-dom";
// import { checkBoxChange } from "@syncfusion/ej2-react-grids";
// CSS
import "../../App.css";
import { connect } from "react-redux";
import DataService from "../../../services/ApiService";
import BreadCrumb from "../CommonSections/BreadCrumb";
import LeftMaterialForm from "./LeftMaterialForm";
import RightMaterialForm from "./RightMaterialForm";
import CustomFieldsTable from "./CustomFieldsTable";

const MaterialView = ({ user }) => {
  const history = useHistory();

  var { RowId } = useParams();

  const [SelectedRowId, setSelectedRowId] = useState(null);

  const [column, setColumn] = useState([]);

  let BaseUnits = JSON.parse(sessionStorage.getItem("StoredUnits")) || [];

  const [StoredUnits, setStoredUnits] = useState([]);

  const [BaseUnitsArray, setBaseUnitsArray] = useState([]);

  const [SelectedMaterialData, setSelectedMaterialData] = useState({
    Material_Id: "",
    Base_Resin: "",
    Manufacturer: "",
    Specific_Gravity: "",
    Min_Melt_Temperature: "",
    Max_Melt_Temperature: "",
    Avg_Melt_Temperature: "",
    Min_Mold_Temperature: "",
    Max_Mold_Temperature: "",
    Avg_Mold_Temperature: "",
    Drying_Temperature: "",
    Drying_Time_Min: "",
    Drying_Time_Max: "",
    Max_Residence_Time: "",
  });

  const [SelectedMaterialsUnitData, setSelectedMaterialsUnitData] = useState({
    Material_Id: "",
    Base_Resin: "",
    Manufacturer: "",
    Specific_Gravity: "",
    Min_Melt_Temperature: "",
    Max_Melt_Temperature: "",
    Avg_Melt_Temperature: "",
    Min_Mold_Temperature: "",
    Max_Mold_Temperature: "",
    Avg_Mold_Temperature: "",
    Drying_Temperature: "",
    Drying_Time_Min: "",
    Drying_Time_Max: "",
    Max_Residence_Time: "",
  });

  const GoToEdit = () => {
    history.push({
      pathname: `/database/Options/${RowId}/MaterialEdit`,
    });
  };

  const GetData = (id) => {
    DataService.GetMaterialsData(id).then((res) => {
      if (res.data.length > 0) {
        if (SelectedRowId) {
          const MaterialData = res.data.filter(
            (item) => item.Material_Id === parseInt(SelectedRowId)
          );

          setSelectedMaterialsUnitData(MaterialData[0].Units);

          setSelectedMaterialData(
            MaterialData[0].Material_Data[0] || MaterialData[0].Material_Data
          );
        }
      } else {
      }

      DataService.GetFieldsData(id).then((res) => {
        if (res.data.length > 0) {
          if (res.data[0].Material_Custom_Fields.length > 0) {
            // console.log(res.data[0].Material_Custom_Fields);

            setColumn(res.data[0].Material_Custom_Fields);
          }
        }
      });
    });
  };

  useEffect(() => {
    if (user) {
      GetData(user.id);
    }
  }, [user, SelectedRowId]);

  useEffect(() => {
    let allUnits = [];

    // Iterate through each category in the data
    for (const category in BaseUnits.Unit_Data[0]) {
      // Add each unit object to the allUnits array
      allUnits = allUnits.concat(BaseUnits.Unit_Data[0][category]);
    }

    setStoredUnits(allUnits);

    setBaseUnitsArray(BaseUnits.Unit_Data[0]);

    setSelectedRowId(atob(RowId));
  }, [RowId]);

  return (
    <>
      <Header2 Title="Database" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <BreadCrumb DB_Name={"Material"} Current_Page={"View"} />
        </div>

        <div className="container-fluid">
          <div className="card p-3 ml-2">
            <div className="pt-2 pb-2">
              <button
                className="btn btn-primary btn-air-primary mr-2"
                onClick={GoToEdit}
              >
                Edit
              </button>
            </div>

            <div className="d-flex col-md-12">
              <div className="col-md-4">
                <div className="form-inline">
                  <LeftMaterialForm
                    SelectedMaterialsUnitData={SelectedMaterialsUnitData}
                    StoredUnits={StoredUnits}
                    BaseUnitsArray={BaseUnitsArray}
                    Page={"View"}
                  />
                </div>
              </div>

              <div className="ml-1  col-md-4">
                <div className="form-inline">
                  <RightMaterialForm
                    SelectedMaterialsUnitData={SelectedMaterialsUnitData}
                    StoredUnits={StoredUnits}
                    BaseUnitsArray={BaseUnitsArray}
                    Page={"View"}
                  />
                </div>
              </div>

              <div className="ml-3 col-md-4">
                <CustomFieldsTable
                  column={column}
                  SelectedMaterialData={SelectedMaterialData}
                  Page={"View"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MaterialView);
