import React from "react";
import { GridComponent } from "@syncfusion/ej2-react-grids";

const Weight = ({
  WeightEnglishDecimals,
  WeightMetricDecimals,
  setWeightEnglishDecimals,
  setWeightMetricDecimals,
  StoredUnits,
  DistanceUnits,
  SpeedUnits,
  PressureUnits,
  TemperatureUnits,
  TimeUnits,
  AreaUnits,
  WeightUnits,
  setSelectedUnitsData,
  showAlert,
}) => {
  // Function to store the metric units data
  const StoreWeightMetricUnits = () => {
    showAlert.current = true;

    setWeightEnglishDecimals(0.12);

    WeightUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Weight[0].unit_id,
      unit_name: StoredUnits.Unit_Data[0].Weight[0].unit_name,
      decimals: WeightMetricDecimals,
    };

    // console.log(WeightUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // Function to store the english units data
  const StoreWeightEnglishUnits = () => {
    showAlert.current = true;

    setWeightMetricDecimals(0.12);

    WeightUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Weight[1].unit_id,
      unit_name: StoredUnits.Unit_Data[0].Weight[1].unit_name,
      decimals: WeightEnglishDecimals,
    };

    // console.log(WeightUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };
  const StoreWeightMetricDecimals = (e) => {
    showAlert.current = true;

    setWeightEnglishDecimals(e.target.value);

    WeightUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Weight[0].unit_id,
      unit_name: StoredUnits.Unit_Data[0].Weight[0].unit_name,
      decimals: parseFloat(e.target.value),
    };

    // console.log(WeightUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // Function to store the english units data
  const StoreWeightEnglishDecimals = (e) => {
    showAlert.current = true;

    setWeightMetricDecimals(e.target.value);

    WeightUnits.current = {
      unit_id: StoredUnits.Unit_Data[0].Weight[1].unit_id,
      unit_name: StoredUnits.Unit_Data[0].Weight[1].unit_name,
      decimals: parseFloat(e.target.value),
    };

    // console.log(WeightUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // HTML input template to bind with the grid for metric part
  function WeightMetricRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="WeightUnit"
        defaultChecked={
          WeightUnits.current.unit_id ===
          StoredUnits.Unit_Data[0].Weight[0].unit_id
            ? true
            : false
        }
        onClick={StoreWeightMetricUnits}
      />
    );
  }

  // HTML input template to bind with the grid for english part
  function WeightEnglishRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="WeightUnit"
        defaultChecked={
          WeightUnits.current.unit_id ===
          StoredUnits.Unit_Data[0].Weight[1].unit_id
            ? true
            : false
        }
        onClick={StoreWeightEnglishUnits}
      />
    );
  }

  // HTML drop down template to bind with the grid for metric part
  function WeightDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        defaultValue={WeightMetricDecimals}
        onChange={StoreWeightMetricDecimals}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  // HTML drop down template to bind with the grid for english part
  function WeightEnglishDropDownTemplate(props) {
    return (
      <select
        className="w-100"
        defaultValue={WeightEnglishDecimals}
        onChange={StoreWeightEnglishDecimals}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  let WeightData = [
    {
      MetricUnit: `${StoredUnits.Unit_Data[0].Weight[0].unit_name}` || null,
      Metric: 20,
      MetricDecimal: 0.12,
      EnglishUnit: `${StoredUnits.Unit_Data[0].Weight[1].unit_name}` || null,
      English: 20,
      EnglishDecimal: 0.12,
    },
  ];

  const WeightColumn = [
    {
      field: "MetricUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "Metric",
      template: WeightMetricRadioTemplate.bind(this),
      headerText: "Metric",
      width: 100,
    },
    {
      field: "MetricDecimal",
      headerText: "Decimals",
      width: 100,
      template: WeightDropDownTemplate.bind(this),
    },
    {
      field: "EnglishUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "English",
      headerText: "English",
      width: 100,
      template: WeightEnglishRadioTemplate.bind(this),
    },
    {
      field: "EnglishDecimal",
      headerText: "Decimals",
      width: 100,
      template: WeightEnglishDropDownTemplate.bind(this),
    },
  ];

  return (
    <div>
      <GridComponent
        id="Grid"
        dataSource={WeightData}
        columns={WeightColumn}
      ></GridComponent>
    </div>
  );
};

export default Weight;
