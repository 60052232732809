import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const AlertModals = ({ ModalStates, ToggleModalStates, UpdateInputConversions, deleteColumn }) => {
  
  return (
    <div>
      <Modal
        isOpen={ModalStates.MaterialIdConfirm.visibility}
        centered={true}
        style={{ width: "500" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.MaterialIdConfirm.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("MaterialIdConfirm")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.DeleteConfirm.visibility}
        centered={true}
        style={{ width: "400px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.DeleteConfirm.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteColumn}>
            {" "}
            Delete{" "}
          </Button>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("DeleteConfirm")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.MaterialIdUnique.visibility}
        centered={true}
        style={{ width: "600px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span> {ModalStates.MaterialIdUnique.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("MaterialIdUnique")}
          >
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={ModalStates.ConvertConfirm.visibility}
        centered={true}
        style={{ width: "600px" }}
      >
        <ModalHeader> Nautilus </ModalHeader>
        <ModalBody>
          <span style={{ fontWeight: "bold" }}> {ModalStates.ConvertConfirm.message} </span>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={UpdateInputConversions}>
            {" "}
            Yes{" "}
          </Button>
          <Button
            color="primary"
            onClick={() => ToggleModalStates("ConvertConfirm")}
          >
            {" "}
            No{" "}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default AlertModals;
