import React from "react";

const DecompressionGrid = ({ PPS_Data }) => {

  const CreateInputData = (event) => {

    const { name, value, dataset } = event.target;

    let data = {

      paramID: dataset.id,
      paramName: name,
      paramUnit: "",
      paramValue: value,

    }

    PPS_Data.Inputs = [
      {
        ...PPS_Data.Inputs[0],
        [dataset.section]: [
          {

            ...PPS_Data.Inputs[0][dataset.section][0],
            [name]: data

          }
        ]
      }
    ]

  }

  return (
    <div id="PPSGrid" className="DecompressionGrid InputsGrid">
      <table>
        <thead>
          <tr>
            <th colSpan="2"> Decompression </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> Distance: </td>
            <td>
              <input className="form-control" tabIndex={1} data-id={"22"} data-section={"Decompression"} name="fldDistance" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].Decompression[0].fldDistance?.paramValue} />
            </td>
          </tr>
          <tr>
            <td> Speed:</td>
            <td>
              {" "}
              <input className="form-control" tabIndex={1} data-id={"23"} data-section={"Decompression"} name="fldSpeed" onChange={CreateInputData}
                defaultValue={PPS_Data.Inputs[0].Decompression[0].fldSpeed?.paramValue} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DecompressionGrid;
