import React from "react";
import { GridComponent } from "@syncfusion/ej2-react-grids";

const Pressure = ({
  PressureMPaMetricDecimals,
  PressurebarMetricDecimals,
  PressurepsiEnglishDecimals,
  PressurekpsiEnglishDecimals,
  StoredUnits,
  DistanceUnits,
  SpeedUnits,
  PressureUnits,
  TemperatureUnits,
  TimeUnits,
  AreaUnits,
  WeightUnits,
  setSelectedUnitsData,
  showAlert
}) => {
  // Function to store the metric units data
  const StorePressureMetricUnits = (data) => {
    // console.log(data)

    showAlert.current = true;

    PressureUnits.current = {
      unit_id:
        data.index === "0"
          ? StoredUnits.Unit_Data[0].Pressure[0].unit_id
          : data.index === "1"
          ? StoredUnits.Unit_Data[0].Pressure[2].unit_id
          : null,
      unit_name:
        data.index === "0"
          ? StoredUnits.Unit_Data[0].Pressure[0].unit_name
          : data.index === "1"
          ? StoredUnits.Unit_Data[0].Pressure[2].unit_name
          : null,
      decimals:
        data.MetricUnit === StoredUnits.Unit_Data[0].Pressure[0].unit_name
          ? PressureMPaMetricDecimals.current
          : data.MetricUnit ===
            StoredUnits.Unit_Data[0].Pressure[2].unit_name
          ? PressurebarMetricDecimals.current
          : null,
    };

    // console.log(PressureUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  // Function to store the english units data
  const StorePressureEnglishUnits = (data) => {
    showAlert.current = true;

    PressureUnits.current = {
      unit_id:
        data.index === "0"
          ? StoredUnits.Unit_Data[0].Pressure[1].unit_id
          : data.index === "1"
          ? StoredUnits.Unit_Data[0].Pressure[3].unit_id
          : null,
      unit_name:
        data.index === "0"
          ? StoredUnits.Unit_Data[0].Pressure[1].unit_name
          : data.index === "1"
          ? StoredUnits.Unit_Data[0].Pressure[3].unit_name
          : null,
      decimals:
        data.EnglishUnit === StoredUnits.Unit_Data[0].Pressure[1].unit_name
          ? PressurepsiEnglishDecimals.current
          : data.EnglishUnit ===
            StoredUnits.Unit_Data[0].Pressure[3].unit_name
          ? PressurekpsiEnglishDecimals.current
          : null,
    };

    // console.log(PressureUnits.current);

    setSelectedUnitsData({
      Speed: SpeedUnits.current,
      Pressure: PressureUnits.current,
      Temperature: TemperatureUnits.current,
      Time: TimeUnits.current,
      Area: AreaUnits.current,
      Weight: WeightUnits.current,
      Distance: DistanceUnits.current,
    });
  };

  function PressureMetricRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="PressureUnit"
        defaultChecked={
          props.index === "0" &&
          PressureUnits.current.unit_name ===
            StoredUnits.Unit_Data[0].Pressure[0].unit_name
            ? true
            : props.index === "1" &&
              PressureUnits.current.unit_name ===
                StoredUnits.Unit_Data[0].Pressure[2].unit_name
            ? true
            : false
        }
        onClick={() => StorePressureMetricUnits(props)}
      />
    );
  }

  function PressureEnglishRadioTemplate(props) {
    return (
      <input
        type="radio"
        name="PressureUnit"
        defaultChecked={
          props.index === "0" &&
          PressureUnits.current.unit_name ===
            StoredUnits.Unit_Data[0].Pressure[1].unit_name
            ? true
            : props.index === "1" &&
              PressureUnits.current.unit_name ===
                StoredUnits.Unit_Data[0].Pressure[3].unit_name
            ? true
            : false
        }
        onClick={() => StorePressureEnglishUnits(props)}
      />
    );
  }

  function PressureMetricDropDownTemplate(props) {
    // console.log(props)

    return (
      <select
        className="w-100"
        defaultValue={
          props.MetricUnit === "MPa"
            ? PressureMPaMetricDecimals.current
            : props.MetricUnit === "bar"
            ? PressurebarMetricDecimals.current
            : null
        }
        onChange={(e) => {
          const value = e.target.value;
          switch (props.MetricUnit) {
            case "MPa":
              PressureMPaMetricDecimals.current = value;
              StorePressureMetricUnits(props);
              break;
            case "bar":
              PressurebarMetricDecimals.current = value;
              StorePressureMetricUnits(props);
              break;
            default:
              // Handle other cases if necessary
              break;
          }
        }}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  function PressureEnglishDropDownTemplate(props) {
    // console.log(props)

    return (
      <select
        className="w-100"
        defaultValue={
          props.EnglishUnit === "psi"
            ? PressurepsiEnglishDecimals.current
            : props.EnglishUnit === "kpsi"
            ? PressurekpsiEnglishDecimals.current
            : 0
        }
        onChange={(e) => {
          const value = e.target.value;
          switch (props.EnglishUnit) {
            case "psi":
              PressurepsiEnglishDecimals.current = value;
              StorePressureEnglishUnits(props);
              break;
            case "kpsi":
              PressurekpsiEnglishDecimals.current = value;
              StorePressureEnglishUnits(props);
              break;
            default:
              // Handle other cases if necessary
              break;
          }
        }}
      >
        <option>{"0"}</option>
        <option>{"0.1"}</option>
        <option>{"0.12"}</option>
        <option>{"0.123"}</option>
        <option>{"0.1234"}</option>
      </select>
    );
  }

  let pressureData = [
    {
      MetricUnit:
        `${StoredUnits.Unit_Data[0].Pressure[0].unit_name}` || null,
      Metric: 20,
      MetricDecimal: 0.12,
      EnglishUnit:
        `${StoredUnits.Unit_Data[0].Pressure[1].unit_name}` || null,
      English: 20,
      EnglishDecimal: 0,
    },
    {
      MetricUnit:
        `${StoredUnits.Unit_Data[0].Pressure[2].unit_name}` || null,
      Metric: 20,
      MetricDecimal: 0.1,
      EnglishUnit:
        `${StoredUnits.Unit_Data[0].Pressure[3].unit_name}` || null,
      English: 20,
      EnglishDecimal: 0,
    },
  ];

  const PressureColumns = [
    {
      field: "MetricUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "Metric",
      template: PressureMetricRadioTemplate.bind(this),
      headerText: "Metric",
      width: 100,
    },
    {
      field: "MetricDecimal",
      headerText: "Decimals",
      template: PressureMetricDropDownTemplate.bind(this),
      width: 100,
    },
    {
      field: "EnglishUnit",
      headerText: "Units",
      width: 100,
    },
    {
      field: "English",
      headerText: "English",
      width: 100,
      template: PressureEnglishRadioTemplate.bind(this),
    },
    {
      field: "EnglishDecimal",
      headerText: "Decimals",
      template: PressureEnglishDropDownTemplate.bind(this),
      width: 100,
    },
  ];

  return (
    <div>
      <GridComponent
        id="Grid"
        dataSource={pressureData}
        columns={PressureColumns}
      ></GridComponent>
    </div>
  );
};

export default Pressure;
