import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header2 from "../common/header-component/header2";
import { Button } from "reactstrap";
import SelectMold from "./SelectMold";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import { get_molds } from "../../actions/auth";
import ModuleCard from "./SubModuleCard";
//import logo from "../../assets/images/Naut.ico"
import { GiHeatHaze, GiStairs } from "react-icons/gi";
import {
  FaFileAlt,
  FaChartLine,
  FaFlask,
  FaWater,
  FaFilePdf,
  FaRegSun,
} from "react-icons/fa";
import SessionCreationForm from "./SessionCreationForm";
import DataService from "../../services/ApiService";
import ManageSessionsTable from "./ManageSessionsTable";

const CardDashboard = ({ user, get_molds }) => {
  const history = useHistory();

  const SelectedMold = JSON.parse(sessionStorage.getItem("SelectedMoldData"))
    ? JSON.parse(sessionStorage.getItem("SelectedMoldData"))
    : [];

  let [SelectedMoldData, setSelectedMoldData] = useState(SelectedMold);

  const [ModalStates, setModalStates] = useState({
    SelectMold: {
      visibility: false,
      message: "Please Select a Mold",
    },
    CreateSession: {
      visibility: false,
      ModuleName: "",
    },
    SessionName: {
      visibility: false,
    },
    ManageSessions: {
      visibility: false,
      SessionName: "",
    },
  });

  const ToggleSelectMoldState = () => {
    setModalStates((prevState) => ({
      ...prevState,
      SelectMold: {
        visibility: !ModalStates.SelectMold.visibility,
        message: ModalStates.SelectMold.message,
      },
    }));
  };

  const ToggleCreateSessionState = (ModuleName) => {

    function isString(input) {
      return typeof input === 'string';
    }

    if (SelectedMoldData.length > 0) {

      if (ModuleName && isString(ModuleName)) {

        setModalStates((prevState) => ({
          ...prevState,
          CreateSession: {
            visibility: !ModalStates.CreateSession.visibility,
            ModuleName: ModuleName,
          },
        }))

      }
      else if (ModuleName === undefined) {

        setModalStates((prevState) => ({
          ...prevState,
          CreateSession: {
            visibility: false,
          },
        }))

      }

    } else {
      ToggleSelectMoldState();
    }
  };

  const ToggleSessionNameModal = () => {
    setModalStates((prevState) => ({
      ...prevState,
      SessionName: {
        visibility: !ModalStates.SessionName.visibility,
      },
    }));
  };

  const ToggleManageSessionModal = (SessionName) => {
    setModalStates((prevState) => ({
      ...prevState,
      ManageSessions: {
        visibility: !ModalStates.ManageSessions.visibility,
        SessionName: SessionName,
      },
    }));
  };

  const OpenDialog = () => {
    const dialogBox = document.getElementById("dialogBox");
    const dialogBoxContainer = document.getElementById("dialogBoxContainer");

    dialogBox.classList.remove("hidden");
    dialogBoxContainer.classList.add("backdrop");
  };

  const closeDialog = () => {
    const dialogBox = document.getElementById("dialogBox");
    const dialogBoxContainer = document.getElementById("dialogBoxContainer");

    dialogBox.classList.add("hidden");
    dialogBoxContainer.classList.remove("backdrop");
  };

  const [SessionData, setSessionData] = useState({
    MeltSessions: [],
    PPSSessions: [],
    SixStepSessions: [],
    QualitySessions: [],
    DOESessions: [],
  });

  // An Local Object to store the Session Data which is stored in the Above Session Array.
  const [addSessionData, setAddSessionData] = useState({
    Session_Name: "",
    Date: "",
  });

  // The event to store the Session Data into the local Object
  const handleAddSessionFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addSessionData };
    newFormData[fieldName] = fieldValue;

    setAddSessionData(newFormData);
  };

  const SaveSessionMethods = {
    MeltPrep: DataService.SaveMeltSession,
    PPS: DataService.SavePPSSession,
    Six_Step_Study: DataService.SaveSession,
    QualityData: DataService.SaveQualitySession,
    DOE: DataService.SaveDoeSession,
  };

  const SessionRouteUrl = {
    MeltPrep: {
      path: (mold_Id, Session_Id, mold_Name, Session_Name) =>
        `/${Session_Id}/${Session_Name}/${mold_Id}/${mold_Name}/meltpreparation`,
      stateKey: "MeltSessions",
    },
    QualityData: {
      path: (mold_Id, Session_Id, mold_Name, Session_Name) =>
        `/${Session_Id}/${Session_Name}/${mold_Id}/${mold_Name}/qualitydata`,
      stateKey: "QualitySessions",
    },
    PPS: {
      path: (mold_Id, Session_Id, mold_Name, Session_Name) =>
        `/${Session_Id}/${Session_Name}/${mold_Id}/${mold_Name}/PPS`,
      stateKey: "PPSSessions",
    },
    Six_Step_Study: {
      path: (Mold_Id, Session_Id, Mold_Name, Session_Name) =>
        `/sixstepstudy/${Mold_Id}/${Session_Id}/${Mold_Name}/${Session_Name}/sixstepstudy`,
      stateKey: "SixStepSessions",
    },
    DOE: {
      path: (Mold_Id, Session_Id, Mold_Name, Session_Name) =>
        // `/sixstepstudy/${Mold_Id}/${Session_Id}/${Mold_Name}/${Session_Name}/sixstepstudy`,
        `/${Mold_Id}/${Session_Id}/${Mold_Name}/${Session_Name}/DOE/doe-new-session`,
      stateKey: "DOESessions",
    },
  };

  const CheckForSessionName = (newSession, ModuleName) => {
    return new Promise((resolve, reject) => {
      let SessionNameExists;

      SessionNameExists = SessionData[ModuleName].some(
        (session) =>
          session.Session_Name.toLowerCase() ===
          newSession.Session_Name.toLowerCase()
      );

      resolve(SessionNameExists);
    });
  };

  // This Event store's the Local Session Object in the main Session Data array.
  const handleAddSessionFormSubmit = (event) => {
    event.preventDefault();

    const newSession = {
      Mold_Name: SelectedMoldData.length > 0 ? SelectedMoldData[0].Mold_Id : "",
      mold: SelectedMoldData.length > 0 ? SelectedMoldData[0].id : "",
      Session_Name: addSessionData.Session_Name,
      Date: addSessionData.Date,
    };

    const Module = ModalStates.CreateSession.ModuleName;

    // Dynamic method lookup based on ModuleName
    const saveSessionMethod = SaveSessionMethods[Module];
    const SessionConfig = SessionRouteUrl[Module];

    CheckForSessionName(newSession, SessionConfig.stateKey).then((sessions) => {
      if (!sessions) {
        // This method is exported from services folder which has different method's of saving and fetching data.
        saveSessionMethod(newSession)
          .then((res) => {
            if (res.data.message) {
            } else {
              var Session_Id = btoa(res.data.id);
              var Mold_Id = btoa(res.data.mold);
              var Mold_Name = btoa(res.data.Mold_Name);
              var Session_Name = btoa(res.data.Session_Name);

              if (SessionConfig) {
                setTimeout(() => {
                  history.push(
                    SessionConfig.path(
                      Mold_Id,
                      Session_Id,
                      Mold_Name,
                      Session_Name
                    )
                  );
                }, 200);

                // Dynamic state update
                setSessionData((prevData) => ({
                  ...prevData,
                  [SessionConfig.stateKey]: [
                    ...SessionData[SessionConfig.stateKey],
                    res.data,
                  ],
                }));

                setAddSessionData([]);
              }
            }
          })
          .catch((err) => { });
      } else {
        ToggleSessionNameModal();
        setAddSessionData([]);
      }
    });
  };

  const NameTitleObj = [
    {
      Name: "MeltPrep",
      Title: "Melt Preparation",
      Session: "MeltSessions",
      Icon: <GiHeatHaze style={{ color: "#ff9800", fontSize: "2em" }} />,
      isSessionBased: true,
    },
    {
      Name: "Six_Step_Study",
      Title: "6 Step Study",
      Session: "SixStepSessions",
      Icon: <GiStairs style={{ color: "#d4145a", fontSize: "2em" }} />,
      isSessionBased: true,
    },
    {
      Name: "DOE",
      Title: "DOE",
      Session: "DOESessions",
      Icon: <FaFlask style={{ color: "#673ab7", fontSize: "2em" }} />,
      isSessionBased: true,
    },
    {
      Name: "PPS",
      Title: "11+2 Plastic Process Sheet",
      Session: "PPSSessions",
      Icon: <FaFileAlt style={{ color: "#3f51b5", fontSize: "2em" }} />,
      isSessionBased: true,
    },

    {
      Name: "QualityData",
      Title: "Quality Data",
      Session: "QualitySessions",
      Icon: <FaChartLine style={{ color: "#f44336", fontSize: "2em" }} />,
      isSessionBased: true,
    },
    {
      Name: "Water_Auxillary_hook_up",
      Title: "Water Auxillary hook up",
      Session: "QualitySessions",
      Icon: <FaWater style={{ color: "#3fa9f5", fontSize: "2em" }} />,
      isSessionBased: false,
    },
    {
      Name: "Model_Trouble_Shooting",
      Title: "Model Trouble Shooting",
      Session: "MoldTroubleShooting",
      Icon: <FaRegSun style={{ color: "#4caf50", fontSize: "2em" }} />,
      isSessionBased: false,
    },
    {
      Name: "Documents",
      Title: "Documents",
      Session: "QualitySessions",
      Icon: <FaFilePdf style={{ color: "#c1272d", fontSize: "2em" }} />,
      isSessionBased: false,
    },
  ];

  // Redirect's to sixStepstudy of that respective session
  const NavigateToModule = (event) => {
    const { name, value } = event.target;

    if (value) {
      // Using the "btoa" method to encrypt the URL so that the exact ID should not be visible
      var Mold_Id = btoa(SelectedMoldData[0].id);
      var Mold_Name = btoa(SelectedMoldData[0].Mold_Id);

      setTimeout(() => {
        if (name === "Six_Step_Study") {
          var Session_Id = btoa(value);
          var Session_Name = btoa(
            SessionData.SixStepSessions.find(
              (sessionData) => sessionData.id === parseInt(value)
            ).Session_Name
          );
          history.push(
            `/sixstepstudy/${Mold_Id}/${Session_Id}/${Mold_Name}/${Session_Name}/sixstepstudy`
          );
        } else if (name === "MeltPrep") {
          var Session_Id = btoa(value);
          var Session_Name = btoa(
            SessionData.MeltSessions.find(
              (sessionData) => sessionData.id === parseInt(value)
            ).Session_Name
          );
          history.push({
            pathname: `${Session_Id}/${Session_Name}/${Mold_Id}/${Mold_Name}/meltpreparation`,
          });
        } else if (name === "PPS") {
          var Session_Id = btoa(value);
          var Session_Name = btoa(
            SessionData.PPSSessions.find(
              (sessionData) => sessionData.id === parseInt(value)
            ).Session_Name
          );
          history.push({
            pathname: `${Session_Id}/${Session_Name}/${Mold_Id}/${Mold_Name}/PPS`,
          });
        } else if (name === "QualityData") {
          var Session_Id = btoa(value);
          var Session_Name = btoa(
            SessionData.QualitySessions.find(
              (sessionData) => sessionData.id === parseInt(value)
            ).Session_Name
          );
          history.push({
            pathname: `${Session_Id}/${Session_Name}/${Mold_Id}/${Mold_Name}/qualitydata`,
            session: value,
          });
        } else if (name === "DOE") {
          var Session_Id = btoa(value);
          var Session_Name = btoa(
            SessionData.DOESessions.find(
              (sessionData) => sessionData.id === parseInt(value)
            ).Session_Name
          );
          history.push({
            pathname: `${Session_Id}/DOE/summary`,
            state: { mold: SelectedMoldData[0].id },
          });
        } else if (name === "PPS") {
          var Session_Id = btoa(value);
          var Session_Name = btoa(
            SessionData.PPSSessions.find(
              (sessionData) => sessionData.id === parseInt(value)
            ).Session_Name
          );

          history.push({
            pathname: `${Session_Id}/${Session_Name}/${Mold_Id}/${Mold_Name}/PPS`,
            session: value,
          });
        }
      }, 100);
    }
  };

  const FetchSessions = () => {
    let id = SelectedMoldData.length > 0 ? SelectedMoldData[0].id : null;

    if (id) {
      // Fetch Melt Sessions
      DataService.FetchMeltSession(id)
        .then((res) => {
          if (!res.data.message) {
            setSessionData((prevData) => ({
              ...prevData,
              MeltSessions: res.data,
            }));
          } else {
            setSessionData((prevData) => ({
              ...prevData,
              MeltSessions: [],
            }));
          }
        })
        .catch((err) => {
          // console.error(err);
        });

      // Fetch Six Step Sessions
      DataService.FetchSession(id)
        .then((res) => {
          if (!res.data.message) {
            setSessionData((prevData) => ({
              ...prevData,
              SixStepSessions: res.data,
            }));
          } else {
            setSessionData((prevData) => ({
              ...prevData,
              SixStepSessions: [],
            }));
          }
        })
        .catch((err) => {
          // console.error(err);
        });

      DataService.FetchPPSSession(id)
        .then((res) => {
          if (!res.data.message) {
            setSessionData((prevData) => ({
              ...prevData,
              PPSSessions: res.data,
            }));
          } else {
            setSessionData((prevData) => ({
              ...prevData,
              PPSSessions: [],
            }));
          }
        })
        .catch((err) => {
          // console.error(err);
        });

      DataService.FetchQualitySession(id)
        .then((res) => {
          if (!res.data.message) {
            setSessionData((prevData) => ({
              ...prevData,
              QualitySessions: res.data,
            }));
          } else {
            setSessionData((prevData) => ({
              ...prevData,
              QualitySessions: [],
            }));
          }
        })
        .catch((err) => {
          // console.error(err);
        });

      DataService.FetchDoeSession(id)
        .then((res) => {
          if (!res.data.message) {
            setSessionData((prevData) => ({
              ...prevData,
              DOESessions: res.data,
            }));
          } else {
            setSessionData((prevData) => ({
              ...prevData,
              DOESessions: [],
            }));
          }
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  };

  useEffect(() => {
    if (user) {
      get_molds(user.id);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    FetchSessions();

    // eslint-disable-next-line
  }, [SelectedMoldData]);

  return (
    <>
      <Header2 Title="Mold" />

      <Modal
        isOpen={ModalStates.SessionName.visibility}
        centered={true}
        style={{ width: "300px" }}
      >
        <ModalHeader toggle={ToggleSessionNameModal}> Nautilus </ModalHeader>
        <ModalBody>
          <span> Session with this name already exists. </span>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={ToggleSessionNameModal}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <SessionCreationForm
        ModalStates={ModalStates}
        ToggleCreateSessionState={ToggleCreateSessionState}
        handleAddSessionFormChange={handleAddSessionFormChange}
        handleAddSessionFormSubmit={handleAddSessionFormSubmit}
        addSessionData={addSessionData}
      />

      <ManageSessionsTable
        ModalStates={ModalStates}
        ToggleManageSessionModal={ToggleManageSessionModal}
        SessionData={
          ModalStates.ManageSessions.SessionName != ""
            ? SessionData[ModalStates.ManageSessions.SessionName]
            : []
        }
        setSessionData={setSessionData}
      />

      <div className="mt-2 container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">
            <div>
              <span className="BreadCrum" style={{ fontSize: "2em" }}>
                {" "}
                Dashboard{" "}
              </span>
            </div>

            <button
              className="btn btn-primary mt-2 mr-4 mb-2 ml-4"
              type="button"
              onClick={OpenDialog}
            >
              Select / Create Mold
            </button>

            <div>
              <div
                className="d-flex text-left mt-2"
                style={{ border: "1px grey solid" }}
              >
                <div className="p-1">
                  <span> Selected Mold Id : </span>
                </div>
                <div className="p-1">
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {SelectedMoldData[0]?.Mold_Id || "NA"}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal
          isOpen={ModalStates.SelectMold.visibility}
          centered={true}
          style={{ width: "300px" }}
        >
          <ModalHeader> Nautilus </ModalHeader>
          <ModalBody>
            <span>
              {" "}
              <strong> {ModalStates.SelectMold.message} </strong>{" "}
            </span>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => ToggleSelectMoldState()}>
              {" "}
              Close{" "}
            </Button>
          </ModalFooter>
        </Modal>

        <SelectMold
          closeDialog={closeDialog}
          setSessionData={setSessionData}
          SelectedMoldData={SelectedMoldData}
          setSelectedMoldData={setSelectedMoldData}
          FetchSessions={FetchSessions}
        />
      </div>

      <div id="main-dashboard" className="d-flex flex-wrap">
        {NameTitleObj.map((item, index) => (
          <div className="col-md-3 mb-4" key={index}>
            <ModuleCard
              ModuleName={item.Name}
              ModuleTitle={item.Title}
              Icon={item.Icon}
              NavigateToModule={NavigateToModule}
              SessionData={SessionData[item.Session]}
              SessionName={item.Session}
              isSessionBased={item.isSessionBased}
              ToggleCreateSessionState={ToggleCreateSessionState}
              ToggleManageSessionModal={ToggleManageSessionModal}
              SelectedMoldData={SelectedMoldData}
            />
          </div>
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  get_molds,
})(CardDashboard);
