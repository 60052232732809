import React, { useEffect, useState, useRef } from "react";

import "../../assets/custom-stylesheet/app2_style.css";
import "../../assets/custom-stylesheet/samplepage_style.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";

import "../App.css";
import Session from "./Session";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import "../App.css";
import { useParams, useHistory } from "react-router-dom";
import DataService from "../../services/ApiService";
import Header2 from "../common/header-component/header2";
import { createSpinner, showSpinner } from "@syncfusion/ej2-popups";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ToastContainer } from "react-toastify";
import Loader from "../../assets/Loader";

const SessionGrid = ({ user }) => {
  const inputRef = useRef(null);

  const history = useHistory();

  // To get the Mold Id from url using useParams hook
  var { MoldName, Mold_Id } = useParams();

  // Variable to store the a mold Name
  const [moldName, setMoldName] = useState();
  const [moldId, setMoldId] = useState();

  const [isLoading, setIsLoading] = useState(true);

  // To toggle Create Session modal
  const [SelectMoldModal, setSelectMoldModal] = useState(false);

  const ToggleSelectMoldModal = () => {
    setSelectMoldModal(!SelectMoldModal);
  };

  const [SessionNameModal, setSessionNameModal] = useState(false);

  const ToggleSessionNameModal = () => {
    setSessionNameModal(!SessionNameModal);
  };

  // Redirect's to sixStepstudy of that respective session
  const handleSession = (session) => {
    // Using the "btoa" method to encrypt the URL so that the exact ID should not be visible
    var Session_Id = btoa(session.id);
    var Mold_Id = btoa(session.mold);
    var Mold_Name = MoldName ? MoldName : btoa(moldName);
    var Session_Name = btoa(session.Session_Name);

    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById("Container"),
    });

    showSpinner(document.getElementById("Container"));

    setTimeout(() => {
      history.push(
        `/sixstepstudy/${Mold_Id}/${Session_Id}/${Mold_Name}/${Session_Name}/sixstepstudy`
      );
    }, 100);

    // console.log(atob(MoldName))
  };

  // To toggle Create Session modal
  const [modal2, setModal2] = useState();

  const toggle2 = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }

    setModal2(!modal2);
  };

  // To set the visiblity of the Select Mold Drop Down List
  const [showDropDown, setShowDropDown] = useState();

  // To Store the Mold's if Drop Down is there
  const [MoldData, setMoldData] = useState([]);

  // To Store the current Mold Data
  const [CurrentMoldData, setCurrentMoldData] = useState([]);

  // These are the state's which store the Session's created by the user.
  const [SessionData, setSessionData] = useState([]);

  // An Local Object to store the Session Data which is stored in the Above Session Array.
  const [addSessionData, setAddSessionData] = useState({
    Session_Name: "",
    Date: "",
  });

  const CheckForSessionName = (newSession) => {
    return new Promise((resolve, reject) => {
      let SessionNameExists;

      SessionNameExists = SessionData.some(
        (session) =>
          session.Session_Name.toLowerCase() ===
          newSession.Session_Name.toLowerCase()
      );

      resolve(SessionNameExists);

      // console.log(SessionNameExists)
    });
  };

  // This Event store's the Local Session Object in the main Session Data array.
  const handleAddFormSubmit2 = (event) => {
    event.preventDefault();

    const newSession = {
      Mold_Name: moldName ? moldName : "",
      mold: moldId,
      Session_Name: addSessionData.Session_Name,
      Date: addSessionData.Date,
    };

    CheckForSessionName(newSession).then((sessions) => {
      // console.log(newSession)

      if (!sessions) {
        // This method is exported from services folder which has different method's of saving and fetching data.
        DataService.SaveSession(newSession)
          .then((res) => {
            if (res.data.message) {
            } else {
              var Session_Id = btoa(res.data.id);
              var Mold_Id = btoa(res.data.mold);
              var Mold_Name = btoa(res.data.Mold_Name);
              var Session_Name = btoa(res.data.Session_Name);

              setTimeout(() => {
                history.push(
                  `/sixstepstudy/${Mold_Id}/${Session_Id}/${Mold_Name}/${Session_Name}/sixstepstudy`
                );
              }, 200);

              // console.log(res.data)

              setSessionData([...SessionData, res.data]);
              setAddSessionData([]);
            }
          })
          .catch((err) => {});
      } else {
        ToggleSessionNameModal();
        setAddSessionData([]);
      }
    });
  };

  // The event to store the Session Data into the local Object
  const handleAddFormChange2 = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addSessionData };
    newFormData[fieldName] = fieldValue;

    setAddSessionData(newFormData);
  };

  // Event to get the created mold's
  const handleGetMold = (id) => {
    DataService.FetchMold(id)
      .then((res) => {
        if (res.data.message) {
          setMoldData([]);
          setIsLoading(false);
        } else {
          setMoldData(res.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };

  // Event to get the created session's
  const handleGetSession = (moldId) => {
    if (moldId && moldId !== 0) {
      // console.log(moldId)

      setIsLoading(true);

      setTimeout(() => {
        // Fetching the Data of Selected Mold, to show in the Mold Detail Grid
        DataService.GetMold(moldId)
          .then((res) => {
            if (res.data) {
              setCurrentMoldData(res.data);
              setMoldName(res.data.Mold_Id);
              // console.log(res.data.Mold_Id)
            } else {
            }
          })
          .catch((err) => {});

        // Fetching Sessions of the selected Mold by Mold Id
        DataService.FetchSession(moldId)
          .then((res) => {
            if (res.data.message) {
              setSessionData([]);
              setCurrentMoldData([]);
              setIsLoading(false);
            } else {
              setSessionData(res.data);
              setIsLoading(false);
            }
          })
          .catch((err) => {});
      }, 500);
    } else {
      // console.log(moldId)

      setSessionData([]);
      setCurrentMoldData([]);
    }
  };

  useEffect(() => {
    // First Checking for the user
    if (user) {
      // This event has a GET api in it
      handleGetMold(user.id);

      // Showing the Drop Down
      setShowDropDown(true);
    } else {
      setSessionData([]);
    }

    if (MoldName && Mold_Id) {

      handleGetSession(atob(Mold_Id));

      setMoldName(atob(MoldName));
      setMoldId(atob(Mold_Id));

    }

    // console.log(moldName, moldId);

  }, [MoldName, Mold_Id, user]);

  const setMold = (e) => {
    var Id = e.target.value;

    setMoldId(Id);

    // console.log(Id);
  };

  return (
    <>
      <Header2 Title="Current Sessions" />

      {isLoading ? <Loader /> : <></>}

      <Modal isOpen={SelectMoldModal} centered={true}>
        <ModalHeader toggle={ToggleSelectMoldModal}>
          {" "}
          Select a mold.{" "}
        </ModalHeader>
        <ModalBody>
          <span> Please select a mold before creating a session. </span>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={ToggleSelectMoldModal}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={SessionNameModal} centered={true}>
        <ModalHeader toggle={ToggleSessionNameModal}> Nautilus </ModalHeader>
        <ModalBody>
          <span> Session with this name already exists. </span>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={ToggleSessionNameModal}>
            {" "}
            Close{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">

        <div className="d-flex">
          <div>
            <span
              className="BreadCrum"
              onClick={() => history.push("/dashboard/mold")}
              style={{ fontSize: "14px", color: "blue" }}
            >
              {" "}
              Molds{" "}
            </span>
          </div>
          <div>
            <span className="BreadCrum" style={{ fontSize: "16px" }}>
              {" "}
              {">"}{" "}
            </span>
          </div>
          <div>
            <span className="BreadCrum" style={{ fontSize: "14px" }}>
              {" "}
              6 step study sessions{" "}
            </span>
          </div>
          </div>

        </div>
      </div>

    <div id="Container" className="container-fluid">
      <div>
        <div className="card ml-4 mr-4 mt-2 mb-4">
          <div className="row d-flex justify-content-evenly">
            <div className="col-md-2 m-4">
              <Session
                modal2={modal2}
                toggle2={toggle2}
                handleAddFormChange2={handleAddFormChange2}
                handleAddFormSubmit2={handleAddFormSubmit2}
                moldName={moldName}
                CurrentMoldData={CurrentMoldData}
                showDropDown={showDropDown}
                addSessionData={addSessionData}
                inputRef={inputRef}
                moldId={moldId}
                ToggleSelectMoldModal={ToggleSelectMoldModal}
              />
            </div>

            {/* Dynamically Showing the Drop Down to select mold's to get session's based on the data we get from the URL we toggle "showDropDown" */}

            {showDropDown && (
              <div className="d-flex justify-content-between m-3">
                {/* Grid to show the details of the selected mold's */}
                <div className="col-md-10">
                  <Table>
                    <thead>
                      <tr>
                        <th> Mold Id </th>
                        <th> Platen Orientation </th>
                        <th> Number Of Bases </th>
                        <th> Number Of Parts </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          {moldId && moldId !== 0
                            ? CurrentMoldData.Mold_Id
                            : ""}{" "}
                        </td>
                        <td>
                          {" "}
                          {moldId && moldId !== 0
                            ? CurrentMoldData.Platen_Orientation
                            : ""}{" "}
                        </td>
                        <td>
                          {" "}
                          {moldId && moldId !== 0
                            ? CurrentMoldData.Number_Of_Bases
                            : ""}{" "}
                        </td>
                        <td>
                          {" "}
                          {moldId && moldId !== 0
                            ? CurrentMoldData.Number_Of_Parts
                            : ""}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <div className="col-md-4 ml-4">
                  <label> Selected Mold: {moldName ? moldName : ""} </label>

                  <select
                    className="form-control"
                    id="MoldDropDown"
                    onChange={setMold}
                    onClick={() => handleGetSession(moldId)}
                  >
                    <option value={0}> Select a mold </option>

                    {MoldData.map((mold, key) => (
                      <option key={key} value={mold.id}>
                        {" "}
                        {mold.Mold_Id}{" "}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>

          {/* Grid to display the session's of the selected Mold */}
          <div id="Container" className="m-2">
            <form autoComplete="off">
              <div className="viscosity_table">
                <Table striped bordered hover responsive variant="light">
                  <thead>
                    <tr>
                      <th className="Pressure_Heading">
                        <span> Session Name </span>
                      </th>
                      <th className="Pressure_Heading">
                        <span> Date </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="grid_style">
                    {SessionData.map((session, sessionId) => (
                      <tr key={SessionData[sessionId].id}>
                        <td>
                          {" "}
                          <input
                            type="text"
                            className="form-control session-link"
                            title="Go to Six Step Study"
                            name="Session_Name"
                            value={session.Session_Name}
                            onClick={() => handleSession(session)}
                            readOnly
                          />{" "}
                        </td>

                        <td>
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            name="Date"
                            value={session.Date}
                            readOnly
                          />{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </form>
          </div>
        </div>
      </div>

      </div>              
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(SessionGrid);
