import React from "react";
import "../../assets/custom-stylesheet/app2_style.css";
import "../../assets/custom-stylesheet/samplepage_style.css";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  Inject,
  FormDesigner,
  FormFields,
} from "@syncfusion/ej2-react-pdfviewer";
import SixStepsPdf from "./SixSteps.pdf";



const SixSteps= () => {
  return (
    <>
      <div id="container">
      <iframe
          src={SixStepsPdf}
          width="100%"
          height="600px"
          title="PDF Viewer"
        />
       
      </div>
    </>
  );
};
export default SixSteps;
